import React, { useState } from 'react';
import Styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import StylesForm from '../../../theme/styles/form.module.scss';

const selectState = (state) => ({
    translate: state.swapi.translate,
});

function StepFiz(props) {
    const state = useSelector(selectState);
    const translate = state.translate;
    const [ name, setName ] = useState('');
    const [ surname, setSurName ] = useState('');
    const [ access, setAccess ] = useState(false);
    const [ error, setError ] = useState({});

    const onClick = () => {
        const error = {};
        if (!surname || surname?.length <= 2) {
            error.surname = true;
        }
        if (!name || name?.length <= 2) {
            error.name = true;
        }
        if (!access) {
            error.access = true;
        }

        if (Object.keys(error).length === 0 && props.callback) {
            props.callback({ name, surname, access });
        } else {
            setError(error);
        }
    };

    return (
        <>
            <img
                alt = ''
                src = { '/images/ver1.jpg' }
            />
            <div className = { Styles.titles }>
                <div className = { Styles.title }>
                    { translate.in6 }
                </div>
                <div className = { Styles.subtitle }>
                    { translate.in7 }
                </div>
            </div>
            <div className = { Styles.form }>
                <div className = { `${StylesForm.field}` }>
                    <div className = { StylesForm.label }>{translate.in27}</div>
                    <input
                        className = { `${StylesForm.input} ${error.name ? StylesForm.error : ''}` }
                        name = { 'name' }
                        placeholder = { translate.in27 }
                        type = { 'text' }
                        value = { name }
                        onChange = { (event) => setName(event.target.value) }
                    />
                </div>
                <div className = { `${StylesForm.field}` }>
                    <div className = { StylesForm.label }>{translate.in28}</div>
                    <input
                        className = { `${StylesForm.input} ${error.surname ? StylesForm.error : ''}` }
                        name = { 'surname' }
                        placeholder = { translate.in28 }
                        type = { 'text' }
                        value = { surname }
                        onChange = { (event) => setSurName(event.target.value) }
                    />
                </div>
                <div className = { `${StylesForm.checkboxField}` }>
                    <div
                        className = { `${StylesForm.checkbox} ${access ? StylesForm.checked : ''} ${error.access ? StylesForm.error : ''}` }
                        onClick = { ()=>setAccess(!access) }>
                        <svg
                            fill = 'none'
                            height = '9'
                            viewBox = '0 0 12 9'
                            width = '12'
                            xmlns = 'http://www.w3.org/2000/svg'>
                            <path
                                d = 'M11.3334 1L4.00002 8.33333L0.666687 5'
                                stroke = 'white'
                                strokeLinecap = 'round'
                                strokeLinejoin = 'round'
                                strokeWidth = '1.25'
                            />
                        </svg>
                    </div>
                    <div className = { StylesForm.label }>
                        {`${translate.in8} `}
                        <a
                            href = '/terms'
                            target = '_blank'>{translate.in9}
                        </a>
                        {` ${translate.in10}`}
                    </div>
                </div>
            </div>
            <div
                className = { Styles.btn }
                onClick = { onClick }>
                { translate.in5 }
                <svg
                    fill = 'none'
                    height = '20'
                    viewBox = '0 0 20 20'
                    width = '20'
                    xmlns = 'http://www.w3.org/2000/svg'>
                    <path
                        clipRule = 'evenodd'
                        d = 'M12.6634 14.7506C12.9859 15.0789 13.5135 15.0837 13.8418 14.7612L18.0842 10.5945C18.2438 10.4378 18.3337 10.2236 18.3337 10C18.3337 9.77638 18.2438 9.56215 18.0842 9.40546L13.8418 5.23879C13.5135 4.9163 12.9859 4.92105 12.6634 5.24941C12.3409 5.57776 12.3456 6.10538 12.674 6.42787L15.4626 9.16667L2.50033 9.16667C2.04009 9.16667 1.66699 9.53976 1.66699 10C1.66699 10.4602 2.04009 10.8333 2.50033 10.8333L15.4626 10.8333L12.674 13.5721C12.3456 13.8946 12.3409 14.4222 12.6634 14.7506Z'
                        fill = '#272727'
                        fillRule = 'evenodd'
                    />
                </svg>
            </div>
        </>
    );
}

export default StepFiz;
