import React, { useEffect, useState } from 'react';
import ListConstructor from '../ListConstructor';
import { listing } from './listing';

import Styles from '../../../theme/styles/pageHeader.module.scss';
import { useSelector } from 'react-redux';
import { setTraslate } from '../../../instruments/setTraslate';
const selectState = (state) => ({
    translate: state.swapi.translate,
});
function AccountserviceBalance(props) {
    const state = useSelector(selectState);
    const translate = state.translate;
    const [ list, setList ] = useState({ ...listing });

    useEffect(()=>{
        const _list = setTraslate({ ...listing }, translate);
        setList(_list);
    }, [ translate, listing ]);

    return (
        <>
            <div className = { Styles.header }>
                <div className = { Styles.title }>
                    { translate.mn8 }
                </div>
            </div>
            <ListConstructor
                { ...props }
                fields = { list }
                limit = { 30 }
                sortDefault = 'createdAt'
                sortOrderDefault = { false }
                type = { 'serviceBalance' }
                urlApi = { '/serviceBalance' }
            />
        </>
    );
}

export default AccountserviceBalance;
