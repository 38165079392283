/* eslint-disable no-extra-parens */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { UsersRouter } from './users';
import { CompanyRouter } from './company';
import { PhoneRouter } from './phone';
import { PostmanRouter } from './postman';
import { AnalyticsRouter } from './analytics';

import { CallsRouter } from './calls';
import { serviceCompanyHistoryRouter } from './serviceCompanyHistory';
import { serviceCompanyRouter } from './serviceCompany';
import { serviceBalanceRouter } from './serviceBalance';
import { SettingRouter } from './setting';
import { ProfitRouter } from './profit';
import { TechRouter } from './tech';

import { list } from '../../Header/menu_list';

const selectState = (state) => ({
    isLogged: state.swapi.isLogged,
    userData: state.swapi.userData,
});

export const AccountRouter = (props) => {
    const state = useSelector(selectState);
    const isLogged = state.isLogged;
    const userData = state.userData;

    const navigate = useNavigate();
    const menuList = list.menuItems;
    const roles = {};
    for (const item of menuList) {
        roles[ item.key ] = item.roles;
    }
    const listRoute = {
        company:               { rout: CompanyRouter },
        users:                 { rout: UsersRouter },
        phone:                 { rout: PhoneRouter },
        postman:               { rout: PostmanRouter },
        analytics:             { rout: AnalyticsRouter },
        calls:                 { rout: CallsRouter },
        serviceCompanyHistory: { rout: serviceCompanyHistoryRouter },
        serviceCompany:        { rout: serviceCompanyRouter },
        serviceBalance:        { rout: serviceBalanceRouter },
        setting:               { rout: SettingRouter },
        [ 'profit/1' ]:        { rout: ProfitRouter },
        tech:                  { rout: TechRouter },

        //copyModule:               { rout: copyModuleRouter },

    };

    for (const module of Object.keys(listRoute)) {
        listRoute[ module ].roles =  roles[ module ] || [];
    }
    useEffect(() => {
        if (!isLogged) {
            const url = '/login';
            navigate(url);
        }
    }, [ isLogged, userData ]);

    const RouteJSX = userData && Object.keys(listRoute).map((module, i) => {
        const Module = listRoute?.[ module ]?.rout;
        const param = {};
        let allow =  (module && listRoute?.[ module ] && listRoute?.[ module ]?.roles.includes(userData.role)) || userData.role === 'admin';

        return (
            <React.Fragment key = { i }>
                { Module && allow && (
                    <Module
                        { ...props }
                        key = { i }
                        { ...param }
                    />
                )}
            </React.Fragment>
        );
    });

    return (
        <>
            {RouteJSX}
        </>
    );
};
