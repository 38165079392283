import React, { useEffect, useState } from 'react';
import ListConstructor from '../ListConstructor';
import { listing } from './listing';
import { useSelector } from 'react-redux';
import { setTraslate } from '../../../instruments/setTraslate';

const selectState = (state) => ({
    userData:  state.swapi.userData,
    translate: state.swapi.translate,
});

function AccountUsers(props) {
    const state = useSelector(selectState);
    const translate = state.translate;
    const userData = state.userData;

    const [ list, setList ] = useState({ ...listing.list });

    useEffect(()=>{
        const _list = setTraslate({ ...listing.list }, translate);
        setList(_list);
    }, [ translate, listing.list ]);

    const listSelect = {
        role: [
            { value: 'manager', label: 'Manager' },
            { value: 'partner', label: 'partner' },
            { value: 'client', label: 'Client' },
        ] };
    if (userData.role === 'admin') {
        listSelect.role.push({ value: 'admin', label: 'Admin' });
    }

    const btns = [
        {
            component: 'WelcomeBtn',
        },
    ];

    return  (
        <>
            <ListConstructor
                { ...props }
                isShowAddBtn
                btns = { btns }
                fields = { list }
                limit = { 30 }
                listSelect = { listSelect }
                moduleTitle = { translate.mn1 }
                sortDefault = 'createdAt'
                sortOrderDefault = { false }
                type = { 'user' }
                urlApi = { '/userList' }
            />
        </>
    );
}

export default AccountUsers;
