import React from 'react';
import ListConstructor from '../ListConstructor';
import { listing } from './listing';
import Styles from '../../../theme/styles/pageHeader.module.scss';

function AccountserviceCompanyHistory(props) {
    return (
        <>
            <div className = { Styles.header }>
                <div className = { Styles.title }>
                    Історія послуги
                </div>
            </div>
            <ListConstructor
                { ...props }
                fields = { listing.list }
                limit = { 30 }
                sortDefault = 'createdAt'
                sortOrderDefault = { false }
                type = { 'serviceCompanyHistory' }
                urlApi = { '/serviceCompanyHistory' }
            />
        </>
    );
}

export default AccountserviceCompanyHistory;
