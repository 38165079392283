/* eslint-disable max-len */
/* eslint-disable no-extra-parens */
import React, { useState, useEffect } from 'react';
import DatePicker, { CalendarContainer, registerLocale } from 'react-datepicker';
import Styles from './styles/DatePicker.module.scss';
import './styles/react-datepicker.css';
import en from 'date-fns/locale/en-GB';
import moment from 'moment';
registerLocale('en', en);

export const DateRangeField = (props) => {
    const { nameField, setFieldValue, disablePrevDates, beforeAllow, label = '', isTime, clear, filter } = props;


    const now = new Date();
    let start = new Date(now.getFullYear(), now.getMonth(), 1); // 1 число поточного місяця
    start = filter?.[ nameField ]?.from ? new Date(filter[ nameField ].from) : start;
    let end = new Date();
    if (filter?.[ nameField ]?.to) {
        end = new Date(filter[ nameField ].to);
        end.setHours(end.getHours() - 5); // Віднімаємо 5 годин
    }


    const [ startDate, setStartDate ] = useState(start);
    const [ endDate, setEndDate ] = useState(end);


    useEffect(() => {
        if (clear) {
            const now = new Date();
            const oneMounth = new Date(now.getFullYear(), now.getMonth(), 1); // 1 число поточного місяця
            setStartDate(oneMounth);

            setEndDate(new Date());
            setFieldValue(nameField, [ oneMounth, new Date() ]);
        }
    }, [ clear ]);


    const onChange = (dates) => {
        const [ start, end ] = dates;
        if (start && end) {
            const startUtcDate = new Date(
                Date.UTC(start.getFullYear(), start.getMonth(), start.getDate()),
            );
            const endUtcDate = new Date(
                Date.UTC(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59, 999),
            );
            setFieldValue(nameField, [ startUtcDate.toISOString(), endUtcDate.toISOString() ]);
        } else  if (start) {
            const startUtcDate = new Date(
                Date.UTC(start.getFullYear(), start.getMonth(), start.getDate()),
            );
            setFieldValue(nameField, startUtcDate.toISOString());
        } else {
            setFieldValue(nameField, '');
        }
        setStartDate(start);
        setEndDate(end);
    };


    // Визначення мінімальної та максимальної дати для вибору
    let minDate = null;

    if (disablePrevDates) {
        minDate = new Date();
    } else if (beforeAllow) {
        minDate = new Date(Date.now() - (beforeAllow * 24 * 60 * 60 * 1000));
    }

    const MyContainer = ({ className, children }) => {
        return (
            <div className = { Styles.calendarWrap }>
                <CalendarContainer className = { className }>
                    <div className = { Styles.dates }>
                        <div className = { Styles.item }>
                            { moment(startDate).format('MMM DD, yyyy') }
                        </div>
                        <div className = { Styles.item }>
                            { endDate && moment(endDate).format('MMM DD, yyyy') }
                        </div>
                    </div>
                    <div style = {{ position: "relative" }}>{children}</div>
                </CalendarContainer>
            </div>
        );
    };

    return (
        <div className = { Styles.wrap }>
            <DatePicker
                selectsRange
                calendarContainer = { MyContainer }
                dateFormat = { props.format ? props.format : 'MMM d, yyyy' }
                endDate = { endDate }
                locale = 'en'
                minDate = { minDate }
                placeholderText = { label }
                popperPlacement = 'bottom-end'
                showMonthYearPicker = { !!props.showMonthYearPicker }
                showTimeSelect = { isTime }
                startDate = { startDate }
                onChange = { (date) => onChange(date) }
            />
        </div>
    );
};
