import React from 'react';
import Styles from './styles.module.scss';
import { useSelector } from 'react-redux';

const selectState = (state) => ({
    translate: state.swapi.translate,
});

function StepFin() {
    const state = useSelector(selectState);
    const translate = state.translate;

    return (
        <>
            <div className = { Styles.titles }>
                <div className = { Styles.title }>
                    { translate.in18 }
                </div>
                <div className = { Styles.subtitle }>
                    { translate.in19 }<br />{ translate.in20 }
                </div>
                <div className = { Styles.subtitle }>
                    { translate.in21 }
                </div>
            </div>
            <img
                alt = ''
                src = { '/images/bubble-gum-man.svg' }
            />
        </>
    );
}

export default StepFin;
