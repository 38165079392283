// eslint-disable-line
import React, { useEffect, useState } from 'react';
import * as fieldForm from '../FormConstructor/export';
import { DateRangeField } from '../FormConstructor/dateRangeField';
import { useSelector } from 'react-redux';
import { setTraslateArr } from '../../../instruments/setTraslate';

const selectState = (state) => ({
    translate: state.swapi.translate,
});

function ListFilter(props) {
    let { fields, name, list, filter, setFilter, setCurrentPage, label } = props;
    const [ dataSearch, setDataSearch ] = useState({});
    const now = new Date();
    const start = new Date(now.setUTCDate(1)).toISOString();
    const [ selectedDate, setSelectedDate ] = useState(start);
    const [ _list, setList ] = useState(list);

    const state = useSelector(selectState);
    const translate = state.translate;

    useEffect(()=>{
        const listTranslate = setTraslateArr(list, translate);
        setList(listTranslate);
    }, [ translate, list ]);

    const onChange = (name, val) => {
        if (Object.keys(dataSearch).length > 0) {
            const clearFilter = { ...filter };
            for (const el of Object.keys(dataSearch)) {
                delete clearFilter[ el ];
            }
        }
        // boroda 21.12.2024
        // поміняв логіку формування фільтра, бо при пошуку по компаніі, в фільтр не попадали дані по компаніі
        if (typeof val === 'object') {
            if (!val.value || val.value.length === 0) {
                delete filter[ name ];
            } else {
                filter[ name ] = val;
            }
        } else {
            filter[ name ] = val;
        }
        if (name === 'country') {
            filter[ name ] = val;
        }

        if (typeof setCurrentPage === 'function') {
            setCurrentPage(1);
        }
        setFilter({ ...filter });
    };

    const onRangeChange = (name, value) => {
        if (Array.isArray(value) && value.length > 0) {
            setSelectedDate(value[ 0 ]);

            setFilter((prev) => {
                return { ...prev, [ name ]: { from: value[ 0 ], to: value[ 1 ] || now } };
            });
        }
    };

    useEffect(() => {
        if (fields[ name ].dataSearch) {
            for (const el of Object.keys(fields[ name ].dataSearch)) {
                const val = filter && filter[ el ] ? filter[ el ] : '';
                if (val && dataSearch[ el ] !== val) {
                    delete filter[ name ];

                    setFilter({ ...filter });
                    dataSearch[ el ] = val;
                }
            }
            setDataSearch(dataSearch);
        }
    }, [ filter ]);

    const Component = fieldForm[ fields[ name ].filter ];
    if (fields[ name ].filter === 'DateField') {
        let nameFieldFrom = 'from';
        let nameFieldTo = 'to';
        if (name === 'start_date') {
            nameFieldFrom = 'start_date_from';
            nameFieldTo = 'start_date_to';
        }

        return Component ? (
            <>
                <Component
                    { ...props }
                    isTime
                    label = { label + ' from' }
                    list = { _list }
                    nameField = { nameFieldFrom }
                    setFieldValue = { onChange }
                    valueField = { filter && filter.from ? filter.from : '' }


                />
                <Component
                    { ...props }
                    isTime
                    label = { label + ' to' }
                    list = { _list }
                    nameField = { nameFieldTo }
                    setFieldValue = { onChange }
                    valueField = { filter && filter.to ? filter.to : '' }

                />
            </>

        ) : null;
    }

    if (fields[ name ].filter === 'DateRangeField') {
        return (
            <DateRangeField
                showStartDate
                filter = { filter }
                label = 'label'
                nameField = { name }
                setFieldValue = { onRangeChange }
                type = 'date'
                valueField = { selectedDate }
            />

        );
    }

    return Component ? (
        <Component
            { ...props }
            edit
            isFilter
            dataSearch = { dataSearch }
            empty = { translate.tx2 }
            label = { label }
            list = { _list }
            nameField = { name }
            placeholder = { label }
            setFieldValue = { onChange }
            valueField = { filter && filter[ name ] ? filter[ name ] : '' }


        />
    ) : null;
}

export default ListFilter;
