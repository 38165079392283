import React from 'react';
import Styles from './styles.module.scss';
import { useSelector } from 'react-redux';

const selectState = (state) => ({
    translate: state.swapi.translate,
});

function StepDocDownload(props) {
    const state = useSelector(selectState);
    const translate = state.translate;

    const onClick = () => {
        if (props.callback) {
            props.callback();
        }
    };

    return (
        <>
            <div className = { Styles.titles }>
                <div className = { Styles.title }>
                    { translate.in23 }
                </div>
                <div className = { Styles.subtitle }>
                    { translate.in24 }
                </div>
            </div>
            <div className = { Styles.upload }>
                <div className = { Styles.files }>
                    <svg
                        fill = 'none'
                        height = '24'
                        viewBox = '0 0 24 24'
                        width = '24'
                        xmlns = 'http://www.w3.org/2000/svg'>
                        <path
                            clipRule = 'evenodd'
                            d = 'M16.6585 13.2526C17.0741 12.8889 17.1163 12.2571 16.7526 11.8415C16.3889 11.4259 15.7571 11.3837 15.3415 11.7474L13 13.7962V4C13 3.44772 12.5523 3 12 3C11.4477 3 11 3.44772 11 4V13.7962L8.6585 11.7474C8.24287 11.3837 7.61111 11.4259 7.24742 11.8415C6.88374 12.2571 6.92586 12.8889 7.3415 13.2526L11.3415 16.7526C11.7185 17.0825 12.2815 17.0825 12.6585 16.7526L16.6585 13.2526ZM4 16C4 15.4477 3.55228 15 3 15C2.44772 15 2 15.4477 2 16V19C2 20.1046 2.89543 21 4 21H20C21.1046 21 22 20.1046 22 19V16C22 15.4477 21.5523 15 21 15C20.4477 15 20 15.4477 20 16V19H4V16Z'
                            fill = 'black'
                            fillRule = 'evenodd'
                        />
                    </svg>
                    <div className = { Styles.title }>
                        { 'Download document' }
                    </div>
                </div>
            </div>
            <div
                className = { Styles.btn }
                onClick = { onClick }>
                { translate.in5 }
                <svg
                    fill = 'none'
                    height = '20'
                    viewBox = '0 0 20 20'
                    width = '20'
                    xmlns = 'http://www.w3.org/2000/svg'>
                    <path
                        clipRule = 'evenodd'
                        d = 'M12.6634 14.7506C12.9859 15.0789 13.5135 15.0837 13.8418 14.7612L18.0842 10.5945C18.2438 10.4378 18.3337 10.2236 18.3337 10C18.3337 9.77638 18.2438 9.56215 18.0842 9.40546L13.8418 5.23879C13.5135 4.9163 12.9859 4.92105 12.6634 5.24941C12.3409 5.57776 12.3456 6.10538 12.674 6.42787L15.4626 9.16667L2.50033 9.16667C2.04009 9.16667 1.66699 9.53976 1.66699 10C1.66699 10.4602 2.04009 10.8333 2.50033 10.8333L15.4626 10.8333L12.674 13.5721C12.3456 13.8946 12.3409 14.4222 12.6634 14.7506Z'
                        fill = '#272727'
                        fillRule = 'evenodd'
                    />
                </svg>
            </div>
        </>
    );
}

export default StepDocDownload;
