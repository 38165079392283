/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import { api } from '../../../Api';
import StylesForm from '../../../theme/styles/form.module.scss';
import Styles from '../../../theme/styles/table.module.scss';
import StylesFilter from '../../../theme/styles/filter.module.scss';
import StylesSelect from '../FormConstructor/styles/select.module.scss';
import { useSelector } from 'react-redux';
const selectState = (state) => ({
    userData:  state.swapi.userData,
    translate: state.swapi.translate,

});


export const Balance = (props) => {
    const state = useSelector(selectState);
    const  userData  = state.userData;
    const  translate  = state.translate;

    const titles = {
        add: translate.st16,
    };
    const { fieldKey, item, setItem } = props;
    const [ open, setOpen ] = useState(false);
    const [ type, setType ] = useState('');
    const [ balance, setBalance ] = useState(0);
    const [ balance2, setBalance2 ] = useState(0);

    const [ amount, setAmount ] = useState('');
    const [ comment, setComment ] = useState('');

    const handleClickOpen = (type) => {
        setType(type);
        setOpen(true);
    };
    useEffect(() => {
        setBalance(item[ fieldKey ]);
        //if (!item.join_balance) {
        const b2 = Number(item?.balance_phone) ? Number(item.balance_phone).toFixed(2) : 0;
        setBalance2(b2);
        //     }
    }, [ item[ fieldKey ] ]);

    const handleClose = () => {
        setOpen(false);
        setAmount('');
    };

    const handleOk = async () => {
        handleClose();
        if (typeof item[ fieldKey ] === 'undefined') {
            item[ fieldKey ] = 0;
        }
        const result = await api.postData({
            field:      fieldKey,
            type:       type,
            amount:     Number(amount),
            comment:    comment,
            balace_old: item[ fieldKey ] || 0,
        }, `/changeBalance/${item._id}`);
        if (typeof result?.data?.[ fieldKey ] === 'number' && result.data[ fieldKey ] > -100000 && setItem) {
            setItem(result?.data);
        }
    };

    return (
        <div className = { `${Styles.wrap}` }>
            <div>${balance || 0} {!item.join_balance ? '/$' + balance2 : ''}   </div>

            {item._id  && userData.role === 'admin' ? (
                <div
                    className = { `${StylesForm.btnOutline}` }
                    onClick = { () => handleClickOpen('add') }>
                    <svg
                        fill = 'none'
                        height = '12'
                        viewBox = '0 0 15 12'
                        width = '15'
                        xmlns = 'http://www.w3.org/2000/svg'>
                        <path
                            clipRule = 'evenodd'
                            d = 'M2.80005 0.666687H10.1334C11.238 0.666687 12.1334 1.56212 12.1334 2.66669V3.33335C13.238 3.33335 14.1334 4.22878 14.1334 5.33335V9.33335C14.1334 10.4379 13.238 11.3334 12.1334 11.3334H4.80005C3.69548 11.3334 2.80005 10.4379 2.80005 9.33335C1.69548 9.33335 0.800049 8.43792 0.800049 7.33335V2.66669C0.800049 1.56212 1.69548 0.666687 2.80005 0.666687ZM4.13338 9.33335C4.13338 9.70154 4.43186 10 4.80005 10H12.1334C12.5016 10 12.8 9.70154 12.8 9.33335V5.33335C12.8 4.96516 12.5016 4.66669 12.1334 4.66669V7.33335C12.1334 8.43792 11.238 9.33335 10.1334 9.33335H4.13338ZM2.80005 2.00002C2.43186 2.00002 2.13338 2.2985 2.13338 2.66669V3.00002H10.8V2.66669C10.8 2.2985 10.5016 2.00002 10.1334 2.00002H2.80005ZM2.13338 7.33335V4.33335H10.8V7.33335C10.8 7.70154 10.5016 8.00002 10.1334 8.00002H2.80005C2.43186 8.00002 2.13338 7.70154 2.13338 7.33335ZM8.13338 6.00002C7.76519 6.00002 7.46671 6.2985 7.46671 6.66669C7.46671 7.03488 7.76519 7.33335 8.13338 7.33335H8.80005C9.16824 7.33335 9.46671 7.03488 9.46671 6.66669C9.46671 6.2985 9.16824 6.00002 8.80005 6.00002H8.13338ZM3.13338 6.66669C3.13338 6.2985 3.43186 6.00002 3.80005 6.00002H5.80005C6.16824 6.00002 6.46672 6.2985 6.46672 6.66669C6.46672 7.03488 6.16824 7.33335 5.80005 7.33335H3.80005C3.43186 7.33335 3.13338 7.03488 3.13338 6.66669Z'
                            fill = '#272727'
                            fillRule = 'evenodd'
                        />
                    </svg>
                </div>
            ) : '' }
            <Dialog
                PaperProps = {{
                    sx: {
                        width:   '500px',
                        padding: '32px',
                        gap:     '24px',
                    },
                }}
                open = { open }
                onClose = { handleClose }>
                <div
                    className = { `${StylesForm.close}` }
                    onClick = { handleClose }
                />
                <div className = { `${StylesForm.title}` }>{titles[ type ] || type}</div>
                <div className = { `${StylesForm.field} ${StylesSelect.wrap}` }>
                    <div className = { StylesForm.label }>{translate.st12}</div>
                    <input
                        className = { `${StylesSelect.input}` }
                        name = { 'amount' }
                        placeholder = { translate.st15 }
                        type = { 'number' }
                        value = { amount }
                        onChange = { (event) => setAmount(event.target.value) }
                    />
                </div>
                <div className = { `${StylesForm.field} ${StylesSelect.wrap}` }>
                    <div className = { StylesForm.label }>{translate.f49}</div>
                    <input
                        className = { `${StylesSelect.input}` }
                        name = { 'comment' }
                        placeholder = { translate.f43 }
                        type = { 'number' }
                        value = { comment }
                        onChange = { (event) => setComment(event.target.value) }
                    />
                </div>
                <div className = { StylesFilter.dialogButtons }>
                    <div
                        className = { StylesFilter.btnOutline }
                        onClick = { () => handleClose() }>
                        {translate.bt1}
                    </div>
                    <div
                        className = { StylesFilter.btn }
                        onClick = { () => handleOk() }>
                        {translate.st14}

                    </div>
                </div>
            </Dialog>
        </div>
    );
};
