const listing = {};

listing.list = {
    companyId: {
        type:        'text',
        title:       'f10',
        width:       '5',
        typeSearch:  'companyId',
        roles:       [ 'admin', 'manager' ],
        sort:        true,
        component:   'SearchV2',
        filter:      'SearchV2',
        sort_filter: 1,
    },
    phone: {
        type:        'text',
        title:       'f31',
        width:       '10',
        sort:        true,
        typeSearch:  'phones',
        component:   'SearchV2',
        filter:      'SearchV2',
        sort_filter: 2,
    },
    date_active: {
        type:  'date',
        title: 'f38',
        width: '10',
        sort:  true,

    },
    next_pay: {
        type:      'date',
        title:     'f39',
        width:     '10',
        sort:      true,
        component: 'DateField',
    },
    price_purchase: {
        type:      'text',
        title:     'f24',
        width:     '10',
        sort:      true,
        component: 'Input',


    },
    price: {
        type:      'text',
        title:     'f41',
        width:     '10',
        // filter: 'Input',
        sort:      true,
        component: 'Input',

    },
    is_reserved: {
        type:  'checkbox',
        title: 'f28',
        width: '9',


    },
    status: {
        type:      'custom',
        title:     'f34',
        width:     '10',
        filter:    'Select',
        component: 'InputCheckbox',

    },
    delete: {
        type:   'delete',
        title:  'f9',
        values: [ 'rowEdit' ],
        width:  '1',
        roles:  [ 'admin' ],

    },

};
listing.listing_additional = {
    companyId: {
        type:        'text',
        title:       'f10',
        width:       '5',
        typeSearch:  'companyId',
        roles:       [ 'admin', 'manager' ],
        sort:        true,
        filter:      'SearchV2',
        sort_filter: 1,
        component:   'SearchV2',

    },
    date_active: {
        type:      'date',
        title:     'f38',
        width:     '10',
        sort:      true,
        component: 'DateField',

    },
    next_pay: {
        type:      'date',
        title:     'f39',
        width:     '10',
        sort:      true,
        component: 'DateField',
    },
    lines_count: {
        type:      'text',
        title:     'f71',
        width:     '10',
        sort:      true,
        component: 'Input',

    },
    comment: {
        type:      'text',
        title:     'f42',
        width:     '10',
        sort:      true,
        component: 'Input',

    },
    price_purchase: {
        type:      'text',
        title:     'f53',
        width:     '10',
        sort:      true,
        component: 'Input',

    },
    price: {
        type:      'text',
        title:     'f43',
        width:     '10',
        sort:      true,
        component: 'Input',


    },
    status: {
        type:        'custom',
        title:       'f34',
        width:       '10',
        filter:      'Select',
        sort_filter: 4,
        component:   'InputCheckbox',
    },
    delete: {
        type:   'delete',
        title:  'f9',
        values: [ 'rowEdit' ],
        width:  '1',
        roles:  [ 'admin' ],

    },

};
export { listing };
