// eslint-disable-line
import React, { useEffect, useState } from 'react';
import ListConstructor from '../ListConstructor';
import { listing } from './listing';
import { SelectedStatusAll } from './selectedStatusAll';
import { Balance } from './balance';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { setTraslate } from '../../../instruments/setTraslate';
const selectState = (state) => ({
    translate: state.swapi.translate,
});
function Postman(props) {
    const { type } = props;
    const state = useSelector(selectState);
    const translate = state.translate;
    const [ list, setList ] = useState({ ...listing.list });
    const [ listAdd, setListAdd ] = useState({ ...listing.list_analit });

    useEffect(()=>{
        const _list = setTraslate({ ...listing.list }, translate);
        setList(_list);
        const _listAdd = setTraslate({ ...listing.list_analit }, translate);
        setListAdd(_listAdd);
    }, [ translate, listing ]);

    const CustomComponents = {
        selectedStatusAll: SelectedStatusAll,
        balance:           Balance,

    };
    const filter = {

        from: moment().date(1)
            .hour(0)
            .minute(0)
            .second(0),
    };

    const tabs = [
        {
            name:   translate.t5,
            to:     '/admin/postman',
            active: type === 'list',
        },
        {
            name:   translate.t6,
            to:     '/admin/postman_analit',
            active: type === 'analit',
        },
    ];

    return (
        <ListConstructor
            { ...props }
            CustomComponents = { CustomComponents }
            fields = { type === 'list' ? list : listAdd  }
            filterOutside = { type === 'analit'  ? filter : {} }
            isDefaultFilter = { type === 'analit' }
            isShowAddBtn = { type === 'list' }
            limit = { 30 }
            moduleTitle = { translate.mn6 }
            sortDefault = { type === 'list' ? 'name' : 'createdAt' }
            sortOrderDefault = { type === 'list' }
            tabs = { tabs }
            type = { type === 'list' ? 'postman' : 'postman_analit' }
            urlApi = { type === 'list' ? '/postman' : '/postman_analit' }
        />
    );
}

export default Postman;
