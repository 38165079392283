/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';

function AccountTerms() {
    return (
        <div style = {{ margin: '50px' }}>
            <p><span lang = 'ru-RU'><strong>Условия пользования личным кабинетом</strong></span></p>
            <p><span lang = 'en-US'><strong>DID</strong></span><span lang = 'en-US'><strong>GLOBAL</strong></span><span lang = 'en-US'><strong>LTD</strong></span><span lang = 'ru-RU'><strong>, общество с ограниченной ответственностью, зарегистрированное согласно законодательства Англии и Уэльса по адресу 3</strong></span><span lang = 'en-US'><strong>RD</strong></span><span lang = 'en-US'><strong>FLOOR</strong></span><span lang = 'en-US'><strong>SUITE</strong></span><span lang = 'ru-RU'><strong> 207 </strong></span><span lang = 'en-US'><strong>REGENT</strong></span><span lang = 'en-US'><strong>STREET</strong></span><span lang = 'ru-RU'><strong>, </strong></span><span lang = 'en-US'><strong>ENGLAND</strong></span><span lang = 'ru-RU'><strong>, </strong></span><span lang = 'en-US'><strong>W</strong></span><span lang = 'ru-RU'><strong>1</strong></span><span lang = 'en-US'><strong>B</strong></span><span lang = 'ru-RU'><strong>3</strong></span><span lang = 'en-US'><strong>HH</strong></span><span lang = 'ru-RU'><strong>, далее именуемая &ndash; Компания, предоставляет своим Клиентам </strong></span><strong>специальный веб-сайт под названием &laquo;Личный кабинет&raquo;</strong><span lang = 'ru-RU'><strong>.</strong></span></p>
            <br/>
            <p><strong>Цель Личного кабинета &mdash; предоставлять Клиентам доступ к информации, относящейся к их Учетной записи (далее именуемой &laquo;</strong><span lang = 'ru-RU'><strong>Личный кабинет</strong></span><strong>&raquo;), обеспечивая для них возможность просматривать и управлять своей Учетной записью клиента, и услугами.</strong></p>
            <br/>
            <p><strong>1. Общие положения</strong></p>
            <br/>
            <p><span lang = 'ru-RU'>1.1 </span><u>Личный кабинет</u> &ndash; это защищенный веб-интерфейс, предоставляемый пользователю услуг IP-телефонии для самостоятельного управления аккаунтом и настройками услуг. Личный кабинет позволяет пользователю в любое время:</p>
            <p><span lang = 'ru-RU'>1.1.1 п</span>олучать информацию о текущем состоянии счета и детализации трафика;</p>
            <p><span lang = 'ru-RU'>1.1.2 п</span>одключать и отключать услуги и дополнительные функции;</p>
            <p><span lang = 'ru-RU'>1.1.3 у</span>правлять тарифными планами и выбирать оптимальные условия обслуживания<span lang = 'ru-RU'> &ndash; пользователем осуществляется через обслуживающего менеджера</span>;</p>
            <p><span lang = 'ru-RU'>1.1.4 п</span>ополнять баланс и отслеживать историю платежей;</p>
            <p><span lang = 'ru-RU'>1.1.5 п</span>олучать техническую поддержку, доступ к справочной информации, а также уведомления о новых услугах и изменениях условий.</p>
            <p><span lang = 'ru-RU'>1.2 Л</span>ичный кабинет служит основным инструментом для пользователя, с помощью которого можно легко и быстро взаимодействовать с сервисом IP-телефонии, контролировать расходы и получать актуальные данные.</p>
            <p><span lang = 'ru-RU'>1.3 </span>Цели использования личного кабинета для управления услугами заключаются в предоставлении пользователю удобного доступа к функциям, связанным с обслуживанием и настройкой услуг.</p>
            <p><span lang = 'ru-RU'>1.3.1 </span>Основные цели включают:</p>
            <p><span lang = 'ru-RU'>1.3.1.1 п</span>росмотр состояния счета и детализации вызововПользователь может отслеживать баланс счета, текущие расходы и детализацию звонков в режиме реального времени, что позволяет контролировать расходы на связь и планировать использование услуг<span lang = 'ru-RU'>;</span></p>
            <p><span lang = 'ru-RU'>1.3.1.2 у</span>правление тарифными планами<span lang = 'ru-RU'>.</span>Личный кабинет предоставляет возможность изучить доступные тарифные планы<span lang = 'ru-RU'> и</span> выбрать оптимальный для нужд пользователя<span lang = 'ru-RU'>.</span></p>
            <p><span lang = 'ru-RU'>1.4 </span>Подключение и отключение дополнительных услуг<span lang = 'ru-RU'> &ndash;</span><span lang = 'uk-UA'> п</span>ользователь может самостоятельно деактивировать услуги<span lang = 'ru-RU'>. Подключение осуществляется через менеджера</span>.</p>
            <p><span lang = 'ru-RU'>1.5 </span>Пополнение счета и отслеживание истории платежей<span lang = 'ru-RU'>ч</span>ерез <span lang = 'ru-RU'>л</span>ичный кабинет <span lang = 'ru-RU'>&ndash; пользователь самостоятельно пополняет</span> счет различными способами, а также просматрива<span lang = 'ru-RU'>ет</span> историю платежей<span lang = 'ru-RU'>.</span><span lang = 'ru-RU'>П</span>олучение отчетов по транзакциям<span lang = 'ru-RU'> осуществляется через менеджера</span>.</p>
            <p><span lang = 'ru-RU'>1.6 </span>Доступ к справочной информации и технической поддержке<span lang = 'ru-RU'>.</span>Пользователь может получить доступ к разделу с инструкциями, рекомендациями по настройке оборудования, а также обратиться в службу поддержки для решения технических вопросов.</p>
            <p><span lang = 'ru-RU'>1.7 </span>Управление уведомлениями и оповещениями<span lang = 'uk-UA'> в</span> личном кабинете<span lang = 'ru-RU'>. Пользователь</span> мож<span lang = 'ru-RU'>ет</span> настроить уведомления о низком балансе, новых услугах, изменениях тарифов и другие важные сообщения, что помогает своевременно получать нужную информацию.</p>
            <br/>
            <p><strong>2. Регистрация и доступ</strong></p>
            <br/>
            <p>Порядок регистрации и создания учетной записи в личном кабинете для пользователей IP-телефонии <span lang = 'ru-RU'>осуществляется по принципу &laquo;знай своего клиента&raquo;. Компания предлагает услуги для юридических и физических лиц. Согласно данного правила пользователь выбирает нужный раздел: &laquo;Физическое лицо&raquo; или &laquo;Юридическое лицо&raquo;, это поможет определить какой тип документов вам необходимо подготовить для загрузки. В дальнейшем авторизация клиента проводится по данным ранее указанных во время регистрации.</span></p>
            <br/>
            <ol start = '2'>
                <ol>
                    <li>
                        <p><span lang = 'ru-RU'><strong>Регистрация пользователя &ndash; физического лица</strong></span><span lang = 'ru-RU'>, осуществляется в несколько этапов:</span></p>
                        <ol>
                            <li>
                                <p><span lang = 'ru-RU'>выбор вида пользователя. На странице регистрации пользователь выбирает необходимую удобную для него форму регистрации &ndash; в данном разделе описана процедура регистрации пользователя как физического лица;</span></p>
                            </li>
                            <li>
                                <p><span lang = 'ru-RU'>п</span>ользователь для входа в личный кабинет<span lang = 'ru-RU'>, указывает свой </span><span lang = 'en-US'>e</span><span lang = 'ru-RU'>-</span><span lang = 'en-US'>mail</span>. Пароль <span lang = 'ru-RU'>соответствующий </span>требованиям безопасности, которые могут включать минимальную длину, использование заглавных и строчных букв, цифр и специальных символов<span lang = 'ru-RU'>, а также ссылку на страницу регистрации получает у обслуживающего менеджера;</span></p>
                            </li>
                            <li>
                                <p><span lang = 'ru-RU'>введение персональных данных, в соответствии с документами, удостоверяющими личность пользователя и ознакомление и подтверждение своего согласия с данными Правилами пользования личным кабинетом;</span></p>
                            </li>
                            <li>
                                <p><span lang = 'ru-RU'>загрузка документов, подтверждающих личность для завершения верификации осуществляется в формате </span><span lang = 'en-US'>jpg</span><span lang = 'ru-RU'> с минимальным разрешением 200 </span><span lang = 'en-US'>dpi</span><span lang = 'ru-RU'>.;</span></p>
                            </li>
                            <li>
                                <p><span lang = 'ru-RU'>по завершении регистрации и верификации, пользователь получит уведомление от менеджера. Верификация обычно длится до 2 часов.</span></p>
                            </li>
                        </ol>
                    </li>
                </ol>
            </ol>
            <br/>
            <ol start = '2'>
                <ol start = '2'>
                    <li>
                        <p><span lang = 'ru-RU'><strong>Регистрация пользователя &ndash; юридического лица</strong></span><span lang = 'ru-RU'>:</span></p>
                        <ol>
                            <li>
                                <p><span lang = 'ru-RU'>регистрация пользователя юридического лица (компании), осуществляется в том же порядке, как и регистрация физического лица, за исключением следующих условий, которые описаны ниже. На странице регистрации пользователь так же выбирает необходимую удобную для него форму регистрации &ndash; в данном разделе описана процедура регистрации пользователя как юридического лица;</span></p>
                            </li>
                            <li>
                                <p><span lang = 'ru-RU'>после прохождения верификации на странице регистрации, пользователь указывает регистрационые данные своей компании, а именно: наименование компании, регистрационный код компании и юридический адрес;</span></p>
                            </li>
                            <li>
                                <p><span lang = 'ru-RU'>загрузка регистрационных документов юридического лица для прохождения процедуры верификации, осуществляется в формате </span><span lang = 'en-US'>PDF</span><span lang = 'ru-RU'>, и может содержать сертификат о регистрации или выписку из торгового реестра или иной юридический документ, выданный компетентными органами страны регистрации компании пользователя, а также документ подтверждающий адрес регистрации и полномочий руководителя. В отдельных случаях может затребоваться документ, подтверждающий актуальность регистрации компании;</span></p>
                            </li>
                            <li>
                                <p><span lang = 'ru-RU'>загрузка договора. Пользователь загружает договор и по ознакомлении с условиями предоставления услуг, описанных в данном договоре, заполняет свои реквизиты и загружает его в формате </span><span lang = 'en-US'>PDF</span><span lang = 'ru-RU'> с обязательным подписанием и оттиском печати. Допускается использования факсимиле или электронной версии печати.</span></p>
                            </li>
                            <li>
                                <p><span lang = 'ru-RU'>по завершении регистрации и верификации, пользователь получит уведомление от менеджера. Верификация обычно длится до 2 часов</span></p>
                            </li>
                        </ol>
                    </li>
                </ol>
            </ol>
            <br/>
            <ol start = '2'>
                <ol start = '3'>
                    <li>
                        <p>Принятие условий использования<span lang = 'ru-RU'> и з</span>авершение регистрации<span lang = 'ru-RU'>.</span><span lang = 'ru-RU'>Д</span>оступ в личный кабинет<span lang = 'ru-RU'>.</span></p>
                    </li>
                </ol>
            </ol>
            <p><span lang = 'ru-RU'>2.3.1 </span>На этапе регистрации пользователь должен ознакомиться с <span lang = 'ru-RU'>данными </span>условиями пользования личного кабинета. Принятие условий фиксируется галочкой.</p>
            <p><span lang = 'ru-RU'>2.3.2 </span>После подтверждения всех данных и принятия условий пользователь получает доступ в личный кабинет, где может начать настройку услуг и управлять своим аккаунтом.</p>
            <p><span lang = 'ru-RU'>2.3.3 </span>В некоторых случаях пользователю предоставляется вводный инструктаж или короткий тур по функциям личного кабинета для упрощения начального пользования<span lang = 'ru-RU'> менеджером, закрепленным за Клиентом</span>.</p>
            <p><span lang = 'ru-RU'>2.3.4 Если пользователь не согласен с данными условиями пользования личным кабинетом, услуги не предоставляются, и пользователь обязан покинуть раздел регистрации.</span></p>
            <br/>
            <ol start = '2'>
                <ol start = '4'>
                    <li>
                        <p>Восстановление доступа в случае утери данных</p>
                    </li>
                </ol>
            </ol>
            <p><span lang = 'ru-RU'>2.4.1 С целью </span>безопасности пользователь может восстановить доступ к учетной записи в случае утери пароля<span lang = 'ru-RU'> исключительно через обслуживающего его менеджера.</span></p>
            <p><strong>3. Обязанности и ответственность пользователя</strong></p>
            <p><span lang = 'ru-RU'>3.1 </span>Пользователь несет ответственность за безопасность своих данных (логин,пароль).Пользователь <span lang = 'ru-RU'>Л</span>ичного кабинета <span lang = 'uk-UA'>обязуется </span>соблюдать <span lang = 'ru-RU'>условия </span>пользования и не передавать доступ третьим лицам<span lang = 'uk-UA'>, кроме того</span>:</p>
            <p><span lang = 'ru-RU'>3.1.1 </span>Соблюдать правила пользования личным кабинетом, установленные провайдером, включая:</p>
            <p><span lang = 'ru-RU'>3.1.1.1 и</span>спользование учетной записи исключительно для управления своими услугами и получения информации, связанной с услугами IP-телефонии;</p>
            <p><span lang = 'ru-RU'>3.1.1.2 з</span>апрещение выполнения любых действий, которые могут повредить системе или повлиять на работу <span lang = 'ru-RU'>Л</span>ичного кабинета и сервиса в целом (например, попытки взлома или несанкционированного доступа к другим учетным записям).</p>
            <p><span lang = 'ru-RU'>3.1.2 </span>Обеспечивать конфиденциальность данных для входа:</p>
            <p><span lang = 'ru-RU'>3.1.2.1 п</span>ользователь обязуется не передавать логин, пароль и другие данные для доступа к личному кабинету третьим лицам. Это условие защищает пользователя от рисков несанкционированного доступа и обеспечивает безопасность персональных данных, финансовой информации и других чувствительных данных.</p>
            <p><span lang = 'ru-RU'>3.1.2.2 о</span>тветственность за любые действия, выполненные с использованием его учетной записи, несет сам пользователь, даже если доступ был передан третьим лицам.</p>
            <p><span lang = 'ru-RU'>3.1.3 </span>Принять меры для защиты учетной записи:</p>
            <p><span lang = 'ru-RU'>3.1.3.1 р</span>екомендуется использовать надежный пароль и периодически его обновлять;</p>
            <p><span lang = 'ru-RU'>3.1.3.2 </span>не сохранять пароль в общедоступных местах.</p>
            <p><span lang = 'ru-RU'>3.1.3.3 н</span>арушение этих обязательств может привести к временной блокировке или полной деактивации учетной записи, если будет установлено, что правила нарушены.</p>
            <p><span lang = 'ru-RU'>3.1.3.4 о</span>граничение на недопустимые действия (например, попытка взлома кабинета, изменение системных настроек).</p>
            <br/>
            <p><strong>4. Права и ответственность компании</strong></p>
            <ol start = '4'>
                <ol>
                    <li>
                        <p><span lang = 'ru-RU'>К</span>омпании <span lang = 'ru-RU'>может в одностороннем порядке </span>приостанов<span lang = 'ru-RU'>ить</span> доступ к личному кабинету пользователя при подозрении на несанкционированные действия<span lang = 'ru-RU'> на время проведения разбирательств</span>.</p>
                        <ol>
                            <li>
                                <p>Основания для приостановления доступа<span lang = 'ru-RU'>. </span>Компания вправе временно приостановить доступ пользователя к личному кабинету IP-телефонии в случае выявления подозрений на несанкционированные действия. К таковым могут относиться:</p>
                            </li>
                        </ol>
                    </li>
                </ol>
            </ol>
            <p><span lang = 'ru-RU'>4.1.1.1 п</span>опытки несанкционированного доступа к системе;</p>
            <p><span lang = 'ru-RU'>4.1.1.2 и</span>спользование личного кабинета для действий, нарушающих законодательство;</p>
            <p><span lang = 'ru-RU'>4.1.1.3 п</span>одозрения на взлом учетной записи или компрометацию данных;</p>
            <p><span lang = 'ru-RU'>4.1.1.4 о</span>бнаружение аномальной активности, несоответствующей обычным действиям пользователя.</p>
            <br/>
            <ol start = '4'>
                <ol start = '2'>
                    <li>
                        <p>Процедура приостановления доступа</p>
                    </li>
                </ol>
            </ol>
            <p><span lang = 'ru-RU'>4.2.1 </span>При обнаружении вышеуказанных обстоятельств компания может приостановить доступ пользователя без предварительного уведомления с целью предотвращения возможного ущерба.</p>
            <p><span lang = 'ru-RU'>4.2.2 </span>О приостановлении доступа пользователь уведомляется по зарегистрированным контактным данным (электронная почта, SMS и/или звонок).</p>
            <p><span lang = 'ru-RU'>4.3 </span>Проверка и восстановление доступа</p>
            <p><span lang = 'ru-RU'>4.3.1 </span>Для восстановления доступа пользователь обязан пройти проверку, подтверждающую его личность, и предоставить компании необходимые данные для анализа ситуации.</p>
            <p><span lang = 'ru-RU'>4.3.2 </span>Компания обязуется рассмотреть запрос на восстановление доступа в <span lang = 'ru-RU'>течении </span>48 часов с момента получения всех необходимых данных.<span lang = 'ru-RU'> Так же замораживаются финансовые операции пользователя на срок до 48 часов во избежание их утраты или несанкционированного использования.</span></p>
            <p><span lang = 'ru-RU'>4.4 </span>Ответственность сторон</p>
            <p><span lang = 'ru-RU'>4.4.1 </span>Компания не несет ответственности за убытки, возникшие в результате временного ограничения доступа, если такие действия были предприняты для защиты учетной записи пользователя.</p>
            <p><span lang = 'ru-RU'>4.4.2 </span>Пользователь обязан обеспечить конфиденциальность своих учетных данных и своевременно информировать компанию о подозрительных действиях, связанных с личным кабинетом.</p>
            <p><span lang = 'ru-RU'>4.4.3 </span>Данное право направлено на обеспечение безопасности пользователей и минимизацию рисков, связанных с нарушениями в системе IP-телефонии.</p>
            <br/>
            <ol start = '4'>
                <ol start = '5'>
                    <li>
                        <p><span lang = 'ru-RU'>И</span>зменения функционала личного кабинета <span lang = 'ru-RU'>проводится с последующим </span>уведомлени<span lang = 'ru-RU'>ем</span> пользователей о таких изменениях.<span lang = 'ru-RU'> Компания </span>с<span lang = 'ru-RU'>обирает</span> и обраб<span lang = 'ru-RU'>атывает</span> статистически<span lang = 'ru-RU'>е</span> данны<span lang = 'ru-RU'>е</span> об использовании личного кабинета для улучшения сервиса, с соблюдением политики конфиденциальности.</p>
                    </li>
                </ol>
            </ol>
            <ol start = '4'>
                <ol start = '5'>
                    <li>
                        <p><span lang = 'ru-RU'>К</span>омпани<span lang = 'ru-RU'>я</span><span lang = 'ru-RU'>обязуется обеспечивать</span> техническ<span lang = 'ru-RU'>ую</span> исправност<span lang = 'ru-RU'>ь</span> личного кабинета<span lang = 'ru-RU'>: </span></p>
                    </li>
                </ol>
            </ol>
            <p><span lang = 'ru-RU'>4.6.1 п</span>оддерживать техническую работоспособность личного кабинета для пользователей;</p>
            <p><span lang = 'ru-RU'>4.6.2 о</span>беспечивать своевременное устранение выявленных неисправностей, связанных с внутренними системами и программным обеспечением компании;</p>
            <p><span lang = 'ru-RU'>4.6.3 и</span>нформировать пользователей о планируемых технических работах, которые могут повлиять на доступ к личному кабинету.</p>
            <br/>
            <ol start = '4'>
                <ol start = '5'>
                    <li>
                        <p>Ограничение ответственности<span lang = 'ru-RU'>. К</span>омпания не несет ответственности за:</p>
                    </li>
                </ol>
            </ol>
            <p><span lang = 'ru-RU'>4.7.1 п</span>рерывания или сбои в работе личного кабинета, вызванные внешними факторами, такими как:</p>
            <p><span lang = 'ru-RU'>4.7.2 п</span>еребои в предоставлении интернета или электроэнергии, находящиеся вне зоны контроля компании;</p>
            <p><span lang = 'ru-RU'>4.7.3 д</span>ействия третьих лиц, включая хакерские атаки или вирусные заражения, если они не связаны с недостатками защиты системы компании;</p>
            <p><span lang = 'ru-RU'>4.7.4 а</span>варийные ситуации на стороне операторов связи или других инфраструктурных поставщиков.</p>
            <p><span lang = 'ru-RU'>4.7.5 у</span>бытки пользователя, связанные с невозможностью доступа к личному кабинету в результате указанных внешних факторов.</p>
            <br/>
            <br/>
            <ol start = '4'>
                <ol start = '5'>
                    <li>
                        <p>Действия в случае сбоев</p>
                    </li>
                </ol>
            </ol>
            <br/>
            <p><span lang = 'ru-RU'>4.8.1 П</span>ри выявлении сбоев, связанных с работой личного кабинета, пользователь обязан оперативно уведомить компанию через доступные каналы связи.Компания обязуется предпринять все разумные меры для устранения сбоев, если их причина находится в зоне ответственности компании.</p>
            <br/>
            <ol start = '4'>
                <ol start = '8'>
                    <ol start = '2'>
                        <li>
                            <p>Гарантии<span lang = 'ru-RU'>. </span>Компания гарантирует, что в отсутствие внешних факторов и при надлежащем использовании личного кабинета его функционал будет соответствовать заявленным требованиям.</p>
                        </li>
                    </ol>
                </ol>
            </ol>
            <br/>
            <ol start = '4'>
                <ol start = '8'>
                    <li>
                        <p><span lang = 'ru-RU'>В случае нарушения данных Правил пользования личным кабинетом, Клиент может быть заблокирован незамедлительно до выяснения всех обстоятельств и компенсации убытков, причиненных Компании таким нарушением, прямых или косвенных.</span></p>
                    </li>
                </ol>
            </ol>
            <p><span lang = 'ru-RU'>4.10 </span>Рекомендации пользователю<span lang = 'ru-RU'>. </span>Для минимизации возможных рисковпользователь обязан<span lang = 'ru-RU'> о</span>беспечить стабильность подключения к интернету и защиту устройства от вредоносного ПО<span lang = 'ru-RU'>. Р</span>егулярно обновлять свои учетные данные для предотвращения несанкционированного доступа.</p>
            <br/>
            <p><strong>5. Конфиденциальность и защита данных</strong></p>
            <br/>
            <ol start = '5'>
                <ol>
                    <li>
                        <p>Порядок обработки и хранения личных данных пользователей<span lang = 'ru-RU'> осуществляется согласно </span><span lang = 'ru-RU'>Политике защиты персональных данных </span><span lang = 'ru-RU'>(</span><span lang = 'ru-RU'>ссылка</span><span lang = 'ru-RU'>)</span><span lang = 'ru-RU'>компании, размещенной в публичном доступе на сайте Компании.</span></p>
                    </li>
                </ol>
            </ol>
            <br/>
            <ol start = '5'>
                <ol start = '2'>
                    <li>
                        <p><span lang = 'ru-RU'>К</span>омпани<span lang = 'ru-RU'>я гарантирует</span> защит<span lang = 'ru-RU'>у</span> данных от несанкционированного доступа и утечек.</p>
                    </li>
                </ol>
            </ol>
            <br/>
            <ol start = '5'>
                <ol start = '2'>
                    <li>
                        <p>Информация о возможности передачи данных третьим лицам (<span lang = 'ru-RU'>партнерам, правоохранительным органам</span>) только в рамках действующего законодательства и с согласия пользователя.</p>
                    </li>
                </ol>
            </ol>
            <p><span lang = 'ru-RU'>5.4 </span>Передача данных третьим лицам</p>
            <p><span lang = 'ru-RU'>5.4.1 </span>Компания вправе передавать данные пользователей услуг третьим лицам исключительно в случаях, предусмотренных действующим законодательством, и с учетом согласия пользователя, выраженного при активации личного кабинета.</p>
            <p><span lang = 'ru-RU'>5.4.2 </span>Основания для передачи данных<span lang = 'ru-RU'>. </span>Данные могут быть переданы третьим лицам только в следующих случаях:</p>
            <p><span lang = 'ru-RU'>5.4.2.1 н</span>а основании официального запроса от правоохранительных органов, суда или иных уполномоченных государственных органов, если запрос соответствует требованиям законодательства;</p>
            <p><span lang = 'ru-RU'>5.4.2.2 д</span>ля выполнения обязательств компании в рамках действующего законодательства (например, налоговой отчетности, расследований мошеннических действий).</p>
            <p><span lang = 'ru-RU'>5.4.3 </span>Согласие пользователя</p>
            <p><span lang = 'ru-RU'>5.4.3.1 п</span>ри подключении личного кабинета пользователь соглашается с условиями обработки и возможной передачи данных, подтверждая это в рамках пользовательского соглашения.</p>
            <p><span lang = 'ru-RU'>5.4.3.2 с</span>огласие распространяется исключительно на случаи, установленные законом, и не предполагает передачи данных в коммерческих или иных целях, не связанных с обеспечением правопорядка.</p>
            <p><span lang = 'ru-RU'>5.4.4 </span>Обеспечение конфиденциальности</p>
            <p><span lang = 'ru-RU'>5.4.4.1 к</span>омпания обязуется передавать минимально необходимый объем данных, запрашиваемый в рамках конкретного случая<span lang = 'ru-RU'>, но в пределах запроса правоохранительных органов, суда на основании закона.</span></p>
            <p><span lang = 'ru-RU'>5.4.4.2 л</span>юбая передача данных осуществляется с соблюдением мер защиты информации, предусмотренных внутренними регламентами компании и нормами законодательства.</p>
            <p><span lang = 'ru-RU'>5.4.5 </span>Уведомление пользователя</p>
            <p><span lang = 'ru-RU'>5.4.5.1 в</span> случаях, не запрещенных законом (например, в рамках тайны следствия), компания уведомляет пользователя о факте передачи данных и обосновании запроса.</p>
            <p><span lang = 'ru-RU'>5.4.5.2 е</span>сли передача данных осуществляется без уведомления пользователя, это возможно только при наличии прямого указания в законодательстве.</p>
            <p><span lang = 'ru-RU'>5.4.6 </span>Ответственность компании<span lang = 'ru-RU'>.</span></p>
            <p><span lang = 'ru-RU'>5.4.6.1 К</span>омпания не несет ответственности за последствия использования переданных данных третьими лицами, если передача была выполнена в строгом соответствии с законодательством и пользовательским соглашением.</p>
            <br/>
            <p><em>Настоящая политика направлена на соблюдение баланса между требованиями</em><em>законодательства и защитой интересов пользователя.</em></p>
            <br/>
            <p><strong>6. Управление услугами</strong></p>
            <p><span lang = 'ru-RU'>6.1 </span>Виды действий, доступных в личном кабинете услуг<span lang = 'ru-RU'>:</span></p>
            <p><span lang = 'ru-RU'>6.1.1 </span>Изменение тарифо<span lang = 'ru-RU'>в. </span>Пользователь может просматривать доступные тарифные планы и выбирать наиболее подходящий.Возможность перехода на новый тарифный план с учетом условий (например, перерасчет или активация с начала следующего расчетного периода)<span lang = 'ru-RU'> &ndash; через своего обслуживающего </span><span lang = 'ru-RU'>менеджера</span>.</p>
            <p><span lang = 'ru-RU'>6.1.2 </span>Отключение услуг, которые больше не требуются, без необходимости обращения в службу поддержки.</p>
            <p><span lang = 'ru-RU'>6.1.3 </span>Пополнение баланса<span lang = 'ru-RU'>. </span>Быстрая и удобная оплата услуг через доступные методы: банковская карта, электронные платежные системы и другие.Возможность настройки автоплатежей для регулярного пополнения.</p>
            <p><span lang = 'ru-RU'>6.1.4 </span>Просмотр отчетов<span lang = 'ru-RU'>. </span>Доступ к детализированным отчетам по использованию услуг, включая:<span lang = 'ru-RU'> с</span>ведения о звонках (дата, время, длительность, стоимость);<span lang = 'ru-RU'> р</span>асходы по услугам за определенный период.</p>
            <p><span lang = 'ru-RU'>6.1.5 </span>История использования услуг<span lang = 'ru-RU'>. </span>Хранение и отображение истории активации/деактивации услуг, изменений тарифов и операций с балансом.Возможность фильтрации информации по дате или категории.</p>
            <p><span lang = 'ru-RU'>6.1.6 </span>Дополнительные возможности (при наличии)<span lang = 'ru-RU'>. </span>Настройка уведомлений (например, об остатке на счете или окончании пакета минут).Обновление личных данных пользователя (контактной информации, пароля и т. д.).Обращение в службу поддержки через чат или форму обратной связи.</p>
            <p><span lang = 'ru-RU'>6.2 </span>Функционал личного кабинета разработан для удобства пользователей, предоставляя возможность управлять услугами IP-телефонии самостоятельно, в любое время и из любого места<span lang = 'ru-RU'>.</span></p>
            <br/>
            <p><strong>7. Уведомления и рассылки</strong></p>
            <br/>
            <ol start = '7'>
                <ol>
                    <li>
                        <p>Форматы уведомления<span lang = 'ru-RU'>. </span>Компания обязуется информировать пользователей о любых важных изменениях в правилах пользования личным кабинетом следующими способами:</p>
                        <ol>
                            <li>
                                <p><span lang = 'ru-RU'>э</span>лектронная почта: уведомление отправляется на адрес, указанный пользователем при регистрации.</p>
                            </li>
                            <li>
                                <p>SMS-сообщение<span lang = 'ru-RU'> или месседжер.</span></p>
                            </li>
                            <li>
                                <p><span lang = 'ru-RU'>у</span>ведомление в личном кабинете: пользователю показывается сообщение о важных изменениях при следующем входе в кабинет.</p>
                            </li>
                        </ol>
                    </li>
                </ol>
            </ol>
            <br/>
            <ol start = '7'>
                <ol start = '2'>
                    <li>
                        <p>Сроки информирования<span lang = 'ru-RU'>:</span></p>
                        <ol>
                            <li>
                                <p><span lang = 'ru-RU'>п</span>ользователь должен быть проинформирован не менее чем за 15 дней до вступления изменений в силу.</p>
                            </li>
                            <li>
                                <p><span lang = 'ru-RU'>в</span> экстренных случаях (например, изменения, связанные с требованиями законодательства или обеспечением безопасности) уведомление может быть предоставлено в сжатые сроки, но не позднее даты вступления изменений в силу.</p>
                            </li>
                        </ol>
                    </li>
                </ol>
            </ol>
            <br/>
            <ol start = '7'>
                <ol start = '3'>
                    <li>
                        <p>Содержание уведомления<span lang = 'ru-RU'>. </span>Уведомление должно включать:</p>
                        <ol>
                            <li>
                                <p><span lang = 'ru-RU'>к</span>раткое описание сути изменений;</p>
                            </li>
                            <li>
                                <p><span lang = 'ru-RU'>д</span>ату вступления изменений в силу;</p>
                            </li>
                            <li>
                                <p><span lang = 'ru-RU'>с</span>сылку на полный текст обновленных правил;</p>
                            </li>
                            <li>
                                <p><span lang = 'ru-RU'>к</span>онтактные данные для получения дополнительных разъяснений (телефон службы поддержки, электронная почта).</p>
                            </li>
                        </ol>
                    </li>
                </ol>
            </ol>
            <br/>
            <ol start = '7'>
                <ol start = '4'>
                    <li>
                        <p>Подтверждение ознакомления</p>
                        <ol>
                            <li>
                                <p><span lang = 'ru-RU'>п</span>ри первом входе в личный кабинет после вступления изменений в силу пользователь должен подтвердить ознакомление с обновленными правилами.</p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p>В случае несогласия с новыми условиями пользователь может связаться с компанией для разрешения ситуации или прекращения использования услуг.</p>
                    </li>
                </ol>
            </ol>
            <br/>
            <p><span lang = 'ru-RU'><strong>8</strong></span><strong>. Прекращение действия соглашения</strong></p>
            <br/>
            <ol start = '8'>
                <ol>
                    <li>
                        <p><span lang = 'ru-RU'>Данные </span>Условия прекращ<span lang = 'ru-RU'>ают свои</span> действия <span lang = 'ru-RU'>с момента удаления учетной записи по инициативе Клиента, прекращения пользования Личным кабинетом и пользования услугами компании, аннулирования Клиенту доступа </span>к личному кабинету<span lang = 'ru-RU'>, за исключением длительности времени возмещения убытка согласно п.4.9, который действует до его полного выполнения.</span></p>
                    </li>
                </ol>
            </ol>
            <br/>
            <ol start = '8'>
                <ol start = '2'>
                    <li>
                        <p>Порядок удаления данных пользователя после расторжения договора<span lang = 'ru-RU'> указаны в </span><span lang = 'ru-RU'>Политике защиты персональных данных </span><span lang = 'ru-RU'>(</span><span lang = 'ru-RU'>ссылка</span><span lang = 'ru-RU'>)</span>.</p>
                    </li>
                </ol>
            </ol>
            <br/>
            <p><span lang = 'ru-RU'><strong>9. Интеллектуальная собственность</strong></span></p>
            <p><span lang = 'ru-RU'>9.1 </span>Вся информация или документы (текст, анимированные или статические изображения, базы данных, звуки, фотографии, экспертные знания и опыт или цитируемые продукты), хранящиеся в Личном кабинете, а также все элементы, созданные для Личного кабинета и его общей структуры, являются собственностью Компании или подпадают под действие прав на использование, воспроизведение и представление,которые были предоставлены.</p>
            <p><span lang = 'ru-RU'>9.2 </span>Данная информация, документы и предметы подпадают под действие законов об охране авторских прав в той мере, в какой они стали доступны общественности через Личный кабинет. Ни одной из сторон не предоставлена лицензия или какое-либо право в отношении прав интеллектуальной собственности, кроме права на просмотр Личного кабинета.<br /><span lang = 'ru-RU'>9.3 </span>Любое воспроизведение или использование копий Личного кабинета категорически запрещено и осуществляется только с предварительного прямого разрешения Компании. Во всех случаях санкционированное воспроизведение информации, размещенной в Личном кабинете, должно содержать указание на источник и соответствующие указания на право собственности.</p>
            <p><span lang = 'ru-RU'>9.4 </span>Если не указано иное, названия компаний, логотипы, продукты и бренды, упоминаемые в Личном кабинете, являются собственностью Компании или являются предметом прав на использование, воспроизведение или представление, которые были предоставлены. Они не могут быть использованы без предварительного письменного разрешения Компании.</p>
            <p><span lang = 'ru-RU'>9.5 </span>Любые базы данных, предоставленные Клиентам, являются собственностью Компании, которая выступает в качестве контролера базы данных. Вы не вправе извлекать или повторно использовать значительную качественную или количественную часть баз данных, в том числе в личных целях.</p>
            <p><strong>1</strong><span lang = 'en-US'><strong>0</strong></span><strong>. Заключительные положения</strong></p>
            <p><span lang = 'ru-RU'>10.1 Возраст пользователя Личным кабинет не может быть менее 18 лет или возраста совершеннолетия установленный законодательно вашей страны регистрации. </span></p>
            <p><span lang = 'ru-RU'>10.2 В случае недостижения совершеннолетия или несогласия с Условиями пользования Личным кабинет, рекомендуем вам покинуть данный портал</span>.</p>
            <p><span lang = 'ru-RU'>10.3 </span>Все приложения, изменения и дополнения к <span lang = 'ru-RU'>Условиям</span> являются их неотъемлемой составной частью.</p>
        </div>

    );
}

export default AccountTerms;
