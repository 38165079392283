import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { book } from '../../../instruments';
import AccountTech from '../Tech';

export const TechRouter = (props) => {
    return (
        <Routes>
            <Route
                exact
                element = {
                    <AccountTech
                        { ...props }
                    />
                }
                path = { book.admin.tech }
            />
        </Routes>
    );
};
