import React, { useState } from 'react';
import { api } from '../../../Api';
import StylesFilter from '../../../theme/styles/filter.module.scss';
import StylesForm from '../../../theme/styles/form.module.scss';
import { useSelector } from 'react-redux';
import { Dialog } from '@mui/material';
import { isEmail } from '../../../instruments/utils';

const selectState = (state) => ({
    translate: state.swapi.translate,
    userData:  state.swapi.userData,
});

function WelcomeBtn() {
    const state = useSelector(selectState);
    const translate = state.translate;
    const userData = state.userData;
    const [ email, setEmail ] = useState('');
    const [ name, setName ] = useState('');
    const [ show, setShow ] = useState(false);
    const [ error, setError ] = useState({});


    const handleClose = () => {
        setShow(false);
        setEmail('');
        setName('');
        setError({});
    };

    const sendData = async () => {
        const response = await api.postData({ email, name }, '/resetPassword');
        if (response && response.status) {
            setShow(false);
            handleClose();
        } else {
            const error = {
                status: true,
            };
            setError(error);
        }
    };

    const handleOk = () => {
        const error = {};
        if (!email || !isEmail(email)) {
            error.email = true;
        }
        if (!name || name?.length <= 2) {
            error.name = true;
        }

        if (Object.keys(error).length === 0) {
            sendData();
        } else {
            setError(error);
        }
    };

    return userData?.role && [ 'manager', 'admin' ].includes(userData?.role) && (
        <>
            <div
                className = { `${StylesFilter.btnOutline}` }
                onClick = { () => setShow(true) }>
                {translate.bt11}
            </div>
            <Dialog
                PaperProps = {{
                    sx: {
                        width:   '500px',
                        padding: '32px',
                        gap:     '24px',
                    },
                }}
                open = { show }
                onClose = { handleClose }>
                <div
                    className = { `${StylesForm.close}` }
                    onClick = { handleClose }
                />
                <div className = { `${StylesForm.title}` }>{translate.bt11}</div>
                <div className = { `${StylesForm.field}` }>
                    <div className = { StylesForm.label }>{translate.f1}</div>
                    <input
                        className = { `${StylesForm.input} ${error.name ? StylesForm.error : ''}` }
                        name = { 'name' }
                        placeholder = { translate.f1 }
                        type = { 'text' }
                        value = { name }
                        onChange = { (event) => setName(event.target.value) }
                    />
                </div>
                <div className = { `${StylesForm.field}` }>
                    <div className = { StylesForm.label }>{translate.f8}</div>
                    <input
                        className = { `${StylesForm.input} ${error.email ? StylesForm.error : ''}` }
                        name = { 'email' }
                        placeholder = { translate.f8 }
                        type = { 'text' }
                        value = { email }
                        onChange = { (event) => setEmail(event.target.value) }
                    />
                </div>
                { error.status && (
                    <div className = { `${StylesForm.error}` }>
                        { translate.tx9 }
                    </div>
                ) }
                <div className = { StylesFilter.dialogButtons }>
                    <div
                        className = { StylesFilter.btnOutline }
                        onClick = { () => handleClose() }>
                        { translate.bt1 }
                    </div>
                    <div
                        className = { StylesFilter.btn }
                        onClick = { () => handleOk() }>
                        { translate.bt11 }
                    </div>
                </div>
            </Dialog>
        </>
    );
}

export default WelcomeBtn;
