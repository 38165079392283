import React, { useState } from 'react';

import { SearchV2 } from '../FormConstructor/searchV2';
import { api } from '../../../Api';
import Styles from '../../../theme/styles/form.module.scss';
import { confirmAlert } from 'react-confirm-alert'; // імпорт бібліотеки

function AccountTech() {
    const [ company_atc, setCompanyAtc ] = useState({});
    const [ company_mor, setCompanyMor ] = useState({});

    const setFieldValue =  (name, value) => {
        if (name === 'company_atc') {
            setCompanyAtc(value);
        }
        if (name === 'company_mor') {
            setCompanyMor(value);
        }
    };
    const onReconnect = async () => {
        if (company_atc &&  company_mor) {
            const result = await api.postData({ company_atc: company_atc.value, company_mor: company_mor.value }, '/company_clearDuplicate');
            console.log(result.names);
            if (result.name) {
                //      setCompanyMor({});
                setCompanyAtc({});
            }
        }
    };
    const handleConfirm = () => {
        if (company_mor?.value === company_atc?.value) {
            return true;
        }
        if (!company_mor?.value || !company_atc?.value) {
            return true;
        }
        confirmAlert({
            title:   'Підтвердження дії',
            message: `Залишаємо за основну ${company_mor?.label}?`,
            buttons: [
                {
                    label:     'Yes',
                    onClick:   () => onReconnect(),
                    className: 'confirm',

                },
                {
                    label:     'No',
                    onClick:   () => console.log('Дію скасовано'),
                    className: 'cancel',

                },
            ],
        });
    };


    const tagReconnect = (
        <div style = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '1200px' }}>
            <div style = {{ textAlign: 'center' }} >Об’єднати компанії</div>

            <div style = {{ width: '350px' }}>

                <SearchV2
                    label = 'company_mor'
                    nameField = 'company_mor'
                    placeholder = 'виберіть ОСНОВНУ компанію'
                    setFieldValue = { setFieldValue }
                    type = 'company'
                    valueField = { company_mor }
                />
            </div>
            <div style = {{ width: '20px' }}></div>
            <div style = {{ width: '350px' }}>

                <SearchV2
                    label = 'company_atc'
                    nameField = 'company_atc'
                    placeholder = 'Виберіть другу компанію'
                    setFieldValue = { setFieldValue }
                    type = 'company'
                    valueField = { company_atc }
                />
            </div>

            <div style = {{ width: '70px' }}>
                <button
                    className = { Styles.button }
                    onClick = { () => handleConfirm() }>
                    Ok
                </button>
            </div>
        </div>
    );

    return (
        <>{ tagReconnect }</>
    );
}

export default AccountTech;
