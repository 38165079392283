import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ItemRender from './itemRender';
import { InputCheckbox } from '../FormConstructor/inputCheckbox';
import Styles from '../../../theme/styles/table.module.scss';

const selectState = (state) => ({
    userData: state.swapi.userData,
});
function ListItem(props) {
    const { fields, data, isAdd,
        handleCheckboxChange, selectedDuplicates = [], isShowDuplicate, updateList } = props;
    const [ saveData, setSaveData ] = useState({});
    const [ item, setItem ] = useState(data);
    const [ error, setError ] = useState('');
    const [ dataSearch, setDataSearch ] = useState({});
    const [ userData, setUserData ] = useState({});
    const state = useSelector(selectState);
    const  _userData  = state.userData;
    useEffect(() => {
        setUserData(_userData);
    }, [  ]);


    useEffect(() => {
        if (error) {
            const timer = setTimeout(function () {
                setError('');
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [ error ]);

    const onChange = (name, val) => {
        const key = name.split('|');
        const nameField = key[ 0 ];
        const newData = { ...saveData };
        newData[ nameField ] = val;

        setSaveData(newData);
    };

    useEffect(() => {
        setItem({ ...item, ...saveData });
    }, [ data ]);


    const fieldsJSX = item
        ? Object.keys(fields).map((key) => {
            let show = true;
            if (fields[ key ].roles && show) {
                show = fields[ key ].roles.includes(userData.role);
            }

            if (fields[ key ].onlyNew && !isAdd) {
                show = false;
            }

            if (!show) {
                return null;
            }
            const disabled = fields[ key ].disabled;
            if (disabled) {
                for (const disabledKey of Object.keys(disabled)) {
                    if (disabled[ disabledKey ] === true) {
                        switch (typeof data[ disabledKey ]) {
                            case 'object':
                                if (data[ disabledKey ].length > 0) {
                                    return null;
                                }
                                break;
                            default:
                                return null;
                        }
                    }
                }
            }

            return (
                <td
                    className = { `${Styles[ key ] ? Styles[ key ] : key}` }
                    key = { key }
                    style = {{ width: `${100 /  Object.keys(fields).length}%` }}>
                    <ItemRender
                        { ...props }
                        dataSearch = { dataSearch }
                        edit = { false }
                        field = { fields[ key ] }
                        fieldKey = { key }
                        item = {{ ...item, ...saveData }}
                        setDataSearch = { setDataSearch }
                        setHidden = { ()=>{
                            if (typeof updateList === 'function') {
                                updateList();
                            }
                        } }
                        setIsSave = { ()=>{
                            //
                        } }
                        setItem = { setItem }
                        onChange = { onChange }
                    />
                </td>
            );
        })
        : null;

    return (
        <tr
            className = { ` ${error ? 'error' : ''}` }
            style = {{ background: item.bg }}>
            {fieldsJSX}
            {isShowDuplicate ? (
                <td style = {{ width: `${100 /  Object.keys(fields).length}%` }}>
                    <InputCheckbox
                        edit
                        nameField = { item._id }
                        setFieldValue = { handleCheckboxChange }
                        valueField =  { selectedDuplicates.includes(item._id) }
                    />
                </td>
            ) : null}
        </tr>
    );
}

export default ListItem;
