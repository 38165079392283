
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ListFilter from '../ListConstructor/filter';
import { api } from '../../../Api';
import Styles from './styles.module.scss';
import moment from 'moment';
import { listing } from './listing';
import {  Drawer, Box  } from '@mui/material';
import StylesFilter from '../../../theme/styles/filter.module.scss';
import { setTraslate } from '../../../instruments/setTraslate';

const selectState = (state) => ({
    translate: state.swapi.translate,
});
function Diagram() {
    const [ data, setData ] = useState([]);
    const [ filter, setFilters ] = useState({});
    const [ openDrawer, setOpenDrawer ] = useState(false);
    const [ list, setList ] = useState({ ...listing.list });

    const state = useSelector(selectState);
    const translate = state.translate;
    useEffect(()=>{
        const _list = setTraslate({ ...listing.list }, translate);
        setList(_list);
    }, [ translate, listing.list ]);

    const [ visibleLines, setVisibleLines ] = useState({
        totalAll:    true,
        totalCalls:  false,
        totalNumber: false,
        totalAtc:    false,
        // totalSms:    false,
        totalSpam:   false,

    });

    const fetchData = async () => {
        try {
            const response = await api.postData({ filterData: filter, limit: 100000, groupDate: true }, `/profit`);
            response.list = response.list.sort((a, b)=>a.lastDate > b.lastDate ? 1 : 0);

            const formattedData = response.list.map((item) => ({
                lastDate:    moment(item.lastDate).format('DD.MM.YYYY'),
                totalAll:    Number(item.totalAll),
                totalCalls:  Number(item.totalCalls),
                totalNumber: Number(item.totalNumber),
                totalAtc:    Number(item.totalAtc),
                totalSpam:   Number(item.totalSpam),
                //totalSms:    item.totalSms,


            }));
            setData(formattedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [ filter ]);


    const toggleLineVisibility = (key) => {
        setVisibleLines((prevState) => ({
            ...prevState,
            [ key ]: !prevState[ key ],
        }));
    };
    const fields = list;
    const filterJSX = fields
        ? Object.keys(fields)
            .filter((key) => {
                const field = fields[ key ];
                field.sort_filter = field.sort_filter ? field.sort_filter : 1;

                return field.sort_filter && field.sort_filter > 0;
            })
            .sort((a, b) => {
                return fields[ a ].sort_filter < fields[ b ].sort_filter ? -1 : 1;
            })
            .map((key) => {
                const field = fields[ key ];

                const resultJSx = field.filter && (
                    <Box sx = {{ display: 'flex', flexDirection: 'column', gap: '8px', marginBottom: '16px' }}>
                        <ListFilter
                            fields = { fields }
                            filter = { filter }
                            label = { field.title }
                            labelTxt = { field.title || '' }
                            name = { key }
                            setFilter = { setFilters }
                            type = { field.typeSearch ? field.typeSearch : key }
                        />
                    </Box>
                );

                return resultJSx ? (
                    <React.Fragment key = { key }>
                        {resultJSx}
                    </React.Fragment>
                ) : null;
            })
        : null;

    return (
        <div className = { Styles.statCalls }>
            <div className = { StylesFilter.wrap } >
                <div
                    className = { StylesFilter.btnOutline }
                    onClick = { () => setOpenDrawer(true) }>
                    <svg
                        fill = 'none'
                        height = '16'
                        viewBox = '0 0 16 16'
                        width = '16'
                        xmlns = 'http://www.w3.org/2000/svg'>
                        <path
                            clipRule = 'evenodd'
                            d = 'M9.60579 15.268C9.09855 15.5679 8.47065 15.5777 7.95432 15.2937L6.43917 14.4604C5.90659 14.1674 5.5757 13.6078 5.5757 13L5.5757 7.75715L1.01138 3.36801C0.521202 2.89664 0.366564 2.17474 0.620647 1.54395C0.874731 0.91316 1.48657 0.5 2.16661 0.5L13.8333 0.5C14.5133 0.5 15.1252 0.91316 15.3792 1.54395C15.6333 2.17475 15.4787 2.89664 14.9885 3.36801L10.4242 7.75715L10.4242 13.8333C10.4242 14.4226 10.113 14.9681 9.60579 15.268ZM7.24237 13L8.75752 13.8333L8.75752 7.40239C8.75752 7.25218 8.79807 7.10627 8.87297 6.9792C8.9111 6.91452 8.95812 6.85472 9.01324 6.80172L13.8333 2.16667L2.16661 2.16667L6.98665 6.80172C7.04177 6.85472 7.08879 6.91452 7.12692 6.9792C7.20182 7.10627 7.24237 7.25218 7.24237 7.40239L7.24237 13Z'
                            fill = '#272727'
                            fillRule = 'evenodd'
                        />
                    </svg>
                    { translate.bt4 }
                    <span className = { StylesFilter.circle } >{Object.keys(filter).length}</span>
                </div>
                <div
                    className = { StylesFilter.clear }
                    onClick = { () => setFilters({}) }>
                    { translate.bt5 }
                </div>
            </div>
            <Drawer
                PaperProps = {{
                    sx: {
                        width:   '400px',  // Ширина Drawer
                        padding: '20px',  // Внутрішні відступи
                    },
                }}
                anchor = 'right'
                open = { openDrawer }
                onClose = { () => setOpenDrawer(false) }>
                <div className = { StylesFilter.drawerContent }>
                    <h2 className = { StylesFilter.h2 }>{translate.bt4}</h2>
                    <div className = { StylesFilter.filtersList }>
                        {filterJSX}
                    </div>
                </div>
            </Drawer>
            <div className = { Styles.toggleButtons }>
                {Object.keys(visibleLines).map((key) => (
                    <label
                        className = { Styles.toggleLabel }
                        key = { key }>
                        <input
                            checked = { visibleLines[ key ] }
                            type = 'checkbox'
                            onChange = { () => toggleLineVisibility(key) }
                        />
                        {key === 'totalAll' &&  translate.f63 }
                        {key === 'totalCalls' &&  translate.f65}
                        {key === 'totalNumber' &&  translate.f66}
                        {key === 'totalAtc' &&  translate.f67}
                        {key === 'totalSms' &&  translate.f68}
                        {key === 'totalSpam' &&  translate.f69}

                    </label>
                ))}
            </div>

            <ResponsiveContainer
                height = { 400 }
                width = '100%'>
                <LineChart
                    data = { data }
                    margin = {{
                        top:    20,
                        right:  30,
                        left:   20,
                        bottom: 5,
                    }}>
                    <CartesianGrid strokeDasharray = '3 3' />
                    <XAxis
                        dataKey = 'lastDate'
                        domain = { [ 0, 'dataMax' ] }
                    />
                    <YAxis domain = { [ 0, 'dataMax' ] }/>
                    <Tooltip />
                    <Legend />
                    {visibleLines.totalAll && (
                        <Line
                            activeDot = {{ r: 8 }}
                            dataKey = 'totalAll'
                            name = { translate.f63 }
                            stroke = '#82ca9d'
                            type = 'monotone'
                        />
                    )}
                    {visibleLines.totalCalls && (
                        <Line
                            activeDot = {{ r: 8 }}
                            dataKey = 'totalCalls'
                            name = { translate.f65 }
                            stroke = '#8884d8'
                            type = 'monotone'
                        />
                    )}
                    {visibleLines.totalNumber && (
                        <Line
                            activeDot = {{ r: 8 }}
                            dataKey = 'totalNumber'
                            name = { translate.f66 }
                            stroke = '#ffc658'
                            type = 'monotone'
                        />
                    )}
                    {visibleLines.totalAtc && (
                        <Line
                            activeDot = {{ r: 8 }}
                            dataKey = 'totalAtc'
                            name = { translate.f67 }
                            stroke = '#ff7300'
                            type = 'monotone'
                        />
                    )}
                    {visibleLines.totalSms && (
                        <Line
                            activeDot = {{ r: 8 }}
                            dataKey = 'totalAtc'
                            name = 'Профіт по СМС'
                            stroke = '#007300'
                            type = 'monotone'
                        />
                    )}
                    {visibleLines.totalSpam && (
                        <Line
                            activeDot = {{ r: 8 }}
                            dataKey = 'totalSpam'
                            name = 'Профіт по спам чекеру'
                            stroke = '#007300'
                            type = 'monotone'
                        />
                    )}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

export default Diagram;
