import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { book } from '../../instruments';
import Login from '../Account/Login/login';
import Home from '../Home';
import Invite from '../Account/Invite';
import AccountTerms from '../Account/Terms';
import AccountTermsEn from '../Account/Terms/en';
import { getCookieLang } from '../../instruments';

export const GlobalRouter = (props) => {
    const lang = getCookieLang();

    return (
        <Routes>
            <Route
                exact
                element = {
                    <Home
                        { ...props }
                    />
                }
                path = { book.home }
            />
            <Route
                exact
                element = {
                    <Login
                        { ...props }
                    />
                }
                path = { book.login }
            />
            <Route
                exact
                element = {
                    <Invite
                        { ...props }
                    />
                }
                path = { book.invite }
            />
            <Route
                exact
                element = {
                    lang !== 'en' ? (
                        <AccountTerms
                            { ...props }
                        />
                    ) : (
                        <AccountTermsEn
                            { ...props }
                        />
                    )

                }
                path = { book.terms }
            />
        </Routes>
    );
};
