/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';

function AccountTerms() {
    return (
        <div style = {{ margin: '50px' }}>
            <p className = 'western'><span lang = 'en-US'><strong>Terms of use</strong></span></p>
            <p
                className = 'western'
                lang = 'en-US'>&nbsp;
            </p>
            <p className = 'western'><span lang = 'en-US'><strong>DID GLOBAL LTD, a limited liability company incorporated under the laws of England and Wales at 3RD FLOOR SUITE 207 REGENT STREET, ENGLAND, W1B3HHH, hereinafter referred to as the Company, provides its Clients with a dedicated website called &lsquo;Personal account&rsquo;.</strong></span></p>
            <p
                className = 'western'
                lang = 'en-US'>&nbsp;
            </p>
            <p className = 'western'><span lang = 'en-US'><strong>The purpose of the personal account is to provide Customers with access to information relating to their Account (hereinafter referred to as &lsquo;Personal account&rsquo;), enabling them to view and manage their Customer Account and services.</strong></span></p>
            <p
                className = 'western'
                lang = 'en-US'>&nbsp;
            </p>
            <p className = 'western'><strong>1. General provisions</strong></p>
            <p className = 'western'>1.1 Personal account is a secure web interface provided to the user of IP-telephony services for independent account management and service settings. The personal account allows the user at any time to:</p>
            <p className = 'western'>1.1.1 receive information about the current account status and traffic details;</p>
            <p className = 'western'>1.1.2 activate and deactivate services and additional functions;</p>
            <p className = 'western'>1.1.3 manage tariff plans and choose optimal service conditions - the user can do this through the service manager;</p>
            <p className = 'western'>1.1.4 top up the balance and track the history of payments;</p>
            <p className = 'western'>1.1.5 receive technical support, access to reference information, as well as notifications about new services and changes in terms and conditions.</p>
            <p className = 'western'>1.2 The Personal account serves as the main tool for the user to easily and quickly interact with the IP-telephony service, control expenses and receive up-to-date data.</p>
            <p
                className = 'western'
                lang = 'en-US'>&nbsp;
            </p>
            <p><span lang = 'en-US'>1.3 The purposes of using the personal account for service management are to provide the user with convenient access to functions related to the maintenance and customisation of services. </span></p>
            <p><span lang = 'en-US'>1.3.1 The main purposes include:</span></p>
            <p><span lang = 'en-US'>1.3.1.1 viewing account status and call details the user can monitor account balance, current charges and call details in real time to control communication costs and plan service usage;</span></p>
            <p><span lang = 'en-US'>1.3.1.2 managing tariff plans. The personal </span>account <span lang = 'en-US'>provides an opportunity to study the available tariff plans and choose the best one for the user's needs.</span></p>
            <p lang = 'en-US'>&nbsp;</p>
            <p><span lang = 'en-US'>1.4 Connection and deactivation of additional services - the user can deactivate services independently. Connection is carried out through the manager.</span></p>
            <p lang = 'en-US'>&nbsp;</p>
            <p className = 'western'><span lang = 'en-US'>1.5 Account replenishment and tracking of payment history via personal </span>account <span lang = 'en-US'>- the user independently replenishes the account in various ways, as well as reviews the history of payments. Transaction reports are received through the manager.</span></p>
            <p
                className = 'western'
                lang = 'en-US'>&nbsp;
            </p>
            <p className = 'western'><span lang = 'en-US'>1.6 Access to reference information and technical support. The user can access the section with instructions, recommendations for setting up the equipment, as well as contact the support team to resolve technical issues.</span></p>
            <p
                className = 'western'
                lang = 'en-US'>&nbsp;
            </p>
            <p className = 'western'><span lang = 'en-US'>1.7 Management of notifications and alerts in personal </span>account<span lang = 'en-US'>. The user can set up notifications about low balance, new services, tariff changes and other important messages, which helps to receive timely information.</span></p>
            <p
                className = 'western'
                lang = 'en-US'>&nbsp;
            </p>
            <p className = 'western'><strong>2. Registration and access</strong></p>
            <p className = 'western'>The procedure of registration and account creation in the personal account for IP-telephony users is based on the &lsquo;know your client&rsquo; principle. The Company offers services for legal entities and individuals. According to this rule the user chooses the necessary section: &lsquo;Natural person&rsquo; or &lsquo;Legal person&rsquo;, it will help to determine what type of documents you need to prepare for uploading. Further authorisation of the client is carried out according to the data previously specified during registration.</p>
            <p
                className = 'western'
                lang = 'en-US'>&nbsp;
            </p>
            <p className = 'western'><span lang = 'en-US'>2.1</span><span lang = 'en-US'><strong> Registration of user - natural person</strong></span><span lang = 'en-US'>, in several stages:</span></p>
            <p><span lang = 'en-US'>2.1.1 selecting the user type. On the registration page, the user chooses the necessary convenient form of registration - this section describes the procedure of registration of the user as an natural person (individual);</span></p>
            <p><span lang = 'en-US'>2.1.2 the user specifies his/her e-mail address to enter the personal account. The password complying with security requirements, which may include minimum length, use of upper and lower case letters, numbers and special symbols, as well as the link to the registration page is received from the service manager;</span></p>
            <p><span lang = 'en-US'>2.1.3 entering personal data in accordance with the documents certifying the user's identity and familiarising and confirming his/her consent to these Rules of using the personal account;</span></p>
            <p><span lang = 'en-US'>2.1.4 uploading identity documents to complete the verification is carried out in jpg format with a minimum resolution of 200 dpi;</span></p>
            <p><span lang = 'en-US'>2.1.5 upon completion of registration and verification, the user will receive a notification from the manager. </span><span lang = 'ru-RU'>Verification usually takes up to 2 hours.</span></p>
            <p lang = 'en-US'>&nbsp;</p>
            <ol start = '2'>
                <ol start = '2'>
                    <li>
                        <p><span lang = 'en-US'><strong>User registration - legal entity (company)</strong></span><span lang = 'en-US'>:</span></p>
                    </li>
                </ol>
            </ol>
            <p><span lang = 'en-US'>2.2.1 user registration of a legal entity (company) is carried out in the same manner as registration of a physical person, except for the following conditions, which are described below. On the registration page the user also chooses the necessary convenient form of registration - this section describes the procedure of registration of the user as a legal entity;</span></p>
            <p><span lang = 'en-US'>2.2.2 after verification on the registration page, the user specifies the registration data of his company, namely: company name, company registration code and legal address;</span></p>
            <p><span lang = 'en-US'>2.2.3 downloading of the registration documents of the legal entity for the verification procedure is carried out in PDF format, and may contain a certificate of registration or extract from the commercial register or other legal document issued by the competent authorities of the country of registration of the user's company, as well as a document confirming the address of registration and the authority of the head. In individual cases, a document confirming the relevance of the company's registration may be requested;</span></p>
            <p><span lang = 'en-US'>2.2.4 downloading the contract. The user uploads the contract and upon familiarisation with the terms and conditions of services described in this contract, fills in his/her details and uploads it in PDF format with obligatory signature and stamp. It is allowed to use a facsimile or electronic version of the seal.</span></p>
            <p><span lang = 'en-US'>2.2.5 upon completion of registration and verification, the user will receive a notification from the manager. Verification usually takes up to 2 hours.</span></p>
            <p>&nbsp;</p>
            <p className = 'western'>2.3 Accepting the terms of use and completing the registration. Access to the personal account.</p>
            <p className = 'western'>2.3.1 At the stage of registration the user must familiarise himself with these terms of use of the <span lang = 'en-US'>personal account</span>. Acceptance of the conditions is recorded by ticking the checkbox.</p>
            <p className = 'western'>2.3.2 After confirming all the data and accepting the terms and conditions, the user gets access to the <span lang = 'en-US'>personal account</span>, where he/she can start setting up services and managing his/her account.</p>
            <p className = 'western'>2.3.3 In some cases, the user is provided with an introductory briefing or a short tour of the functions of the <span lang = 'en-US'>personal account </span>to simplify the initial use of the manager assigned to the Client.</p>
            <p className = 'western'>2.3.4 If the user does not agree with these terms of use of the personal account, the services are not provided and the user is obliged to leave the registration section.</p>
            <p className = 'western'>&nbsp;</p>
            <p className = 'western'>2.4 Restoration of access in case of data loss</p>
            <p className = 'western'>2.4.1 For security reasons, the user can only restore access to the account in case of password loss via the manager who serves him.</p>
            <p
                className = 'western'
                lang = 'uk-UA'>&nbsp;
            </p>
            <p className = 'western'><strong>3. Duties and responsibilities of the user</strong></p>
            <p className = 'western'><span lang = 'en-US'>3.1 The User is responsible for the security of his/her data (login, password). The User of personal account undertakes to comply with the terms of use and not to transfer access to third parties, in addition:</span></p>
            <p
                className = 'western'
                lang = 'en-US'>&nbsp;
            </p>
            <p className = 'western'><span lang = 'en-US'>3.1.1 Comply with the rules of use of the personal account established by the provider, including:</span></p>
            <p className = 'western'><span lang = 'en-US'>3.1.1.1.1 use the account solely to manage its services and obtain information related to IP telephony services;</span></p>
            <p className = 'western'><span lang = 'en-US'>3.1.1.2 prohibit any actions that may damage the system or affect the operation of personal account and the service as a whole (e.g. hacking attempts or unauthorised access to other accounts).</span></p>
            <p
                className = 'western'
                lang = 'en-US'>&nbsp;
            </p>
            <p className = 'western'><span lang = 'en-US'>3.1.2 Ensure confidentiality of login data:</span></p>
            <p className = 'western'><span lang = 'en-US'>3.1.2.1 the user undertakes not to transfer login, password and other data for access to the personal account to third parties. This condition protects the user from the risks of unauthorised access and ensures the security of personal data, financial information and other sensitive data.</span></p>
            <p className = 'western'><span lang = 'en-US'>3.1.2.2 the user is responsible for any actions performed using his/her account, even if access has been transferred to third parties.</span></p>
            <p
                className = 'western'
                lang = 'en-US'>&nbsp;
            </p>
            <p className = 'western'><span lang = 'en-US'>3.1.3 Take measures to protect your account:</span></p>
            <p className = 'western'><span lang = 'en-US'>3.1.3.1 it is recommended that you use a strong password and update it periodically;</span></p>
            <p className = 'western'><span lang = 'en-US'>3.1.3.2 do not save the password in publicly accessible places.</span></p>
            <p className = 'western'><span lang = 'en-US'>3.1.3.3 breach of these obligations may result in temporary suspension or complete deactivation of the account if it is determined that the rules have been violated.</span></p>
            <p className = 'western'><span lang = 'en-US'>3.1.3.4 restriction on unacceptable actions (e.g. attempting to hack into the account, changing system settings).</span></p>
            <p
                className = 'western'
                lang = 'en-US'>&nbsp;
            </p>
            <p className = 'western'><strong>4. Rights and responsibility of the company</strong></p>
            <p className = 'western'>4.1 The Company may unilaterally suspend access to the user's personal account in case of suspected unauthorised actions for the duration of the proceedings.</p>
            <p className = 'western'>4.1.1 Grounds for suspension of access. The Company has the right to temporarily suspend the user's access to the personal account of IP-telephony in case of suspicion of unauthorised actions. Such actions may include:</p>
            <p className = 'western'>4.1.1.1.1 attempts of unauthorised access to the system;</p>
            <p className = 'western'>4.1.1.2 use of the personal account for actions violating the legislation;</p>
            <p className = 'western'>4.1.1.3 suspicion of account hacking or data compromise;</p>
            <p className = 'western'>4.1.1.4 detection of abnormal activity inconsistent with the user's normal activities.</p>
            <p className = 'western'>&nbsp;</p>
            <p className = 'western'>4.2 Access Suspension Procedure</p>
            <p className = 'western'>4.2.1 If the above circumstances are detected, the company may suspend the user's access without prior notice in order to prevent possible damage.</p>
            <p className = 'western'>4.2.2 The user is notified of the access suspension via the registered contact details (email, SMS and/or call).</p>
            <p
                className = 'western'
                lang = 'ru-RU'>&nbsp;
            </p>
            <p><span lang = 'en-US'>4.3 Verification and Restoration of Access</span></p>
            <p><span lang = 'en-US'>4.3.1 In order to restore access, the user is obliged to undergo a verification of his/her identity and provide the company with the necessary data to analyse the situation.</span></p>
            <p><span lang = 'en-US'>4.3.2 The company is obliged to consider the request for access restoration within 48 hours from the moment of receipt of all necessary data. Also the user's financial transactions are frozen for up to 48 hours to prevent their loss or unauthorised use.</span></p>
            <p lang = 'en-US'>&nbsp;</p>
            <p><span lang = 'en-US'>4.4 Responsibility of the parties</span></p>
            <p><span lang = 'en-US'>4.4.1 The Company shall not be liable for any losses incurred as a result of temporary restriction of access, if such actions were taken to protect the user's account.</span></p>
            <p><span lang = 'en-US'>4.4.2 The User is obliged to ensure the confidentiality of his/her credentials and to inform the Company in a timely manner about suspicious activities related to the personal account.</span></p>
            <p><span lang = 'en-US'>4.4.3 This right is aimed at ensuring the safety of users and minimising the risks associated with violations in the IP telephony system. </span></p>
            <p lang = 'en-US'>&nbsp;</p>
            <p className = 'western'><span lang = 'en-US'>4.5 Changes in the functionality of the personal account are carried out with subsequent notification of users about such changes. The Company collects and processes statistical data on the use of personal account to improve the service, with observance of the confidentiality policy.</span></p>
            <p
                className = 'western'
                lang = 'en-US'>&nbsp;
            </p>
            <p className = 'western'><span lang = 'en-US'>4.6 The Company undertakes to ensure technical operability of personal account: </span></p>
            <p className = 'western'><span lang = 'en-US'>4.6.1 maintain technical operability of the personal account for users;</span></p>
            <p className = 'western'><span lang = 'en-US'>4.6.2 ensure timely elimination of identified malfunctions related to internal systems and software of the Company;</span></p>
            <p className = 'western'><span lang = 'en-US'>4.6.3 inform users about planned technical works that may affect access to the personal account.</span></p>
            <p
                className = 'western'
                lang = 'en-US'>&nbsp;
            </p>
            <p className = 'western'><span lang = 'en-US'>4.6 Limitation of Liability. The Company shall not be liable for:</span></p>
            <p className = 'western'><span lang = 'en-US'>4.7.1 interruptions or failures in the operation of the personal account caused by external factors such as:</span></p>
            <p className = 'western'><span lang = 'en-US'>4.7.2 internet or power outages beyond the Company's control;</span></p>
            <p className = 'western'><span lang = 'en-US'>4.7.3 actions of third parties, including hacker attacks or virus infections, if they are not related to deficiencies in the Company's system defences;</span></p>
            <p className = 'western'><span lang = 'en-US'>4.7.4 emergencies on the side of telecom operators or other infrastructure providers.</span></p>
            <p className = 'western'><span lang = 'en-US'>4.7.5 losses of the user due to inability to access the personal account as a result of these external factors.</span></p>
            <p
                className = 'western'
                lang = 'ru-RU'>&nbsp;
            </p>
            <p className = 'western'>4.8 Actions in case of failures</p>
            <p className = 'western'>4.8.1 If any failures related to the work of the personal account are detected, the user is obliged to promptly notify the company through available communication channels. The Company undertakes to take all reasonable measures to eliminate failures, if their cause is within the Company's area of responsibility.</p>
            <p className = 'western'>4.8.2 Warranties. The Company guarantees that in the absence of external factors and with proper use of the personal account its functionality will meet the stated requirements.</p>
            <p className = 'western'>&nbsp;</p>
            <p className = 'western'>4.9 In case of violation of these Rules of use of personal account, the Client can be blocked immediately until clarification of all circumstances and compensation of losses caused to the Company by such violation, direct or indirect.</p>
            <p className = 'western'>&nbsp;</p>
            <p className = 'western'>4.10 Recommendations to the user. To minimise possible risks, the user is obliged to ensure stability of the Internet connection and protection of the device from malware. Regularly update your credentials to prevent unauthorised access.</p>
            <p
                className = 'western'
                lang = 'ru-RU'>&nbsp;
            </p>
            <p className = 'western'><strong>5. Confidentiality and data protection</strong></p>
            <p className = 'western'>5.1 The order of processing and storage of personal data of users is carried out according to the <span lang = 'en-US'>Personal Data Protection Policy (link)</span>of the Company, placed in public access on the Company's website.</p>
            <p className = 'western'>&nbsp;</p>
            <p className = 'western'>5.2 The Company guarantees data protection from unauthorised access and leaks.</p>
            <p className = 'western'>&nbsp;</p>
            <p className = 'western'>5.3 Information about the possibility of data transfer to third parties (partners, law enforcement agencies) only within the framework of the current legislation and with the consent of the user.</p>
            <p className = 'western'>&nbsp;</p>
            <p className = 'western'>5.4 Data transfer to third parties</p>
            <p className = 'western'>5.4.1 The Company has the right to transfer the data of service users to third parties only in cases stipulated by the current legislation and taking into account the consent of the user expressed during the activation of the personal account.</p>
            <p className = 'western'>&nbsp;</p>
            <p className = 'western'>5.4.2 Grounds for data transfer. Data may be transferred to third parties only in the following cases:</p>
            <p className = 'western'>5.4.2.1 on the basis of an official request from law enforcement agencies, court or other authorised state bodies, if the request complies with the legal requirements;</p>
            <p className = 'western'>5.4.2.2. to fulfil the company's obligations under applicable law (e.g. tax reporting, fraud investigations).</p>
            <p
                className = 'western'
                lang = 'en-US'>&nbsp;
            </p>
            <p className = 'western'><span lang = 'en-US'>5.4.3 User consent</span></p>
            <p className = 'western'><span lang = 'en-US'>5.4.3.1 upon activation of the personal account, the user agrees to the terms and conditions of data processing and possible transfer of data, confirming this within the framework of the user agreement.</span></p>
            <p className = 'western'><span lang = 'en-US'>5.4.3.2 the consent applies only to the cases established by law and does not imply the transfer of data for commercial or other purposes not related to law enforcement.</span></p>
            <p
                className = 'western'
                lang = 'en-US'>&nbsp;
            </p>
            <p className = 'western'><span lang = 'en-US'>5.4.4 Privacy Policy</span></p>
            <p className = 'western'><span lang = 'en-US'>5.4.4.1 the company agrees to transfer the minimum necessary amount of data requested within the scope of a specific case, but within the limits of the request of law enforcement agencies, court on the basis of law.</span></p>
            <p className = 'western'><span lang = 'en-US'>5.4.4.2 any data transfer shall be carried out in compliance with the data protection measures stipulated in the company's internal regulations and legal provisions.</span></p>
            <p
                className = 'western'
                lang = 'en-US'>&nbsp;
            </p>
            <p className = 'western'><span lang = 'en-US'>5.4.5 User notification</span></p>
            <p className = 'western'><span lang = 'en-US'>5.4.5.1 in cases not prohibited by law (e.g. in the context of investigative secrecy), the company will notify the user of the fact of the data transfer and the reason for the request.</span></p>
            <p className = 'western'><span lang = 'en-US'>5.4.5.2 if the data transfer takes place without notifying the user, this is only possible if explicitly stated by law.</span></p>
            <p
                className = 'western'
                lang = 'en-US'>&nbsp;
            </p>
            <p className = 'western'><span lang = 'en-US'>5.4.6 Company's responsibility.</span></p>
            <p className = 'western'><span lang = 'en-US'>5.4.6.1 The company is not liable for the consequences of the use of the transferred data by third parties, provided that the transfer was carried out in strict compliance with the law and the user agreement.</span></p>
            <p
                className = 'western'
                lang = 'en-US'>&nbsp;
            </p>
            <p className = 'western'><span lang = 'en-US'><em>This policy is aimed at maintaining a balance between the requirements of the law and the protection of the user's interests.</em></span></p>
            <p
                className = 'western'
                lang = 'en-US'>&nbsp;
            </p>
            <p className = 'western'><strong>6. Service management</strong></p>
            <p className = 'western'><span lang = 'en-US'>6.1 Types of actions available in the personal account of services:</span></p>
            <p className = 'western'><span lang = 'en-US'>6.1.1 Changing tariffs. The user can view the available tariff plans and select the most suitable one. It is possible to switch to a new tariff plan subject to conditions (e.g. recalculation or activation from the beginning of the next billing period) - through their service manager.</span></p>
            <p className = 'western'><span lang = 'en-US'>6.1.2 Cancellation of services that are no longer required without the need to contact the support service.</span></p>
            <p className = 'western'><span lang = 'en-US'>6.1.3 Top-up. Fast and convenient payment for services via available methods: bank card, electronic payment systems and others. Possibility to set up autopayments for regular top-ups.</span></p>
            <p className = 'western'><span lang = 'en-US'>6.1.4 View reports. Access to detailed reports on service usage, including: information on calls (date, time, duration, cost); service costs for a certain period.</span></p>
            <p className = 'western'><span lang = 'en-US'>6.1.5 Service usage history. Storing and displaying the history of service activation/deactivation, tariff changes and balance operations. Possibility to filter information by date or category.</span></p>
            <p className = 'western'><span lang = 'en-US'>6.1.6 Additional features (if any). Customisation of notifications (e.g. account balance or minutes package expiry). Updating the user's personal data (contact information, password, etc.). Contacting support via chat or feedback form.</span></p>
            <p
                className = 'western'
                lang = 'en-US'>&nbsp;
            </p>
            <p className = 'western'><span lang = 'en-US'>6.2 The functionality of the personal </span>account <span lang = 'en-US'>is designed for the convenience of users, providing an opportunity to manage IP-telephony services independently, at any time and from any place.</span></p>
            <p
                className = 'western'
                lang = 'en-US'>&nbsp;
            </p>
            <p className = 'western'><span lang = 'ru-RU'><strong>7</strong></span><strong>. Notifications and mailings</strong></p>
            <p className = 'western'>7.1 Notification Formats. The Company undertakes to inform users about any important changes in the rules of using the personal account in the following ways:</p>
            <p className = 'western'>7.1.1 e-mail: the notification is sent to the address specified by the user during registration.</p>
            <p className = 'western'>7.1.2 SMS or messenger.</p>
            <p className = 'western'>7.1.3 notification in the personal account: the user is shown a message about important changes the next time he/she logs in to the cabinet.</p>
            <p className = 'western'>&nbsp;</p>
            <p className = 'western'>7.2 Timing of informing:</p>
            <p className = 'western'>7.2.1 the user must be informed at least 15 days before the changes come into effect.</p>
            <p className = 'western'>7.2.2 in urgent cases (e.g. changes related to legal or security requirements) the notification can be provided in short time, but not later than the effective date of the changes.</p>
            <p className = 'western'>&nbsp;</p>
            <p className = 'western'>7.3 Content of the notice. The notice shall include:</p>
            <p className = 'western'>7.3.1 a brief description of the substance of the changes;</p>
            <p className = 'western'>7.3.2 the effective date of the changes;</p>
            <p className = 'western'>7.3.3 a link to the full text of the updated rules;</p>
            <p className = 'western'>7.3.4 contact details for further clarification (support phone number, e-mail).</p>
            <p className = 'western'>&nbsp;</p>
            <p className = 'western'>7.4 Confirmation of familiarisation</p>
            <p className = 'western'>7.4.1 when logging in to the personal account for the first time after the changes come into effect, the user must confirm familiarisation with the updated rules.</p>
            <p className = 'western'>7.5 In case of disagreement with the new terms and conditions, the user can contact the company to resolve the situation or stop using the services.</p>
            <p
                className = 'western'
                lang = 'ru-RU'>&nbsp;
            </p>
            <p className = 'western'><span lang = 'en-US'><strong>8</strong></span><strong>. Termination of the agreement</strong></p>
            <p className = 'western'><span lang = 'en-US'>8.1 These Terms of use terminate from the moment of deletion of the account on the Client's initiative, termination of use of the personal account and use of the company's services, cancellation of the Client's access to the personal account, except for the duration of the indemnification period according to clause 4.9, which is valid until its full execution.</span></p>
            <p
                className = 'western'
                lang = 'en-US'>&nbsp;
            </p>
            <p className = 'western'><span lang = 'en-US'>8.2 The procedure for deleting user data after termination of the agreement is specified in the </span><span lang = 'en-US'>Personal Data Protection Policy (link).</span></p>
            <p
                className = 'western'
                lang = 'en-US'>&nbsp;
            </p>
            <p className = 'western'><span lang = 'ru-RU'><strong>9. Intellectual property</strong></span></p>
            <p className = 'western'><span lang = 'en-US'>9.1 All information or documents (text, animated or static images, databases, sounds, photographs, expertise or quoted products) stored in personal account and all elements created for personal account and its overall structure are the property of the Company or are subject to the rights of use, reproduction and representation that have been granted. </span></p>
            <p className = 'western'><span lang = 'en-US'>9.2 This information, documents and items are subject to copyright laws to the extent that they are made available to the public through the Personal Account. No licence or any right in respect of intellectual property rights other than the right to view the Personal Account has been granted to either party.</span></p>
            <p className = 'western'><span lang = 'en-US'>9.3 Any reproduction or use of copies of the Personal Account is expressly prohibited and is only with the prior express authorisation of the Company. In all cases, authorised reproduction of information posted in personal account must indicate the source and relevant proprietary rights.</span></p>
            <p className = 'western'><span lang = 'en-US'>9.4 Unless otherwise stated, company names, logos, products and brands mentioned in personal account are the property of the Company or are subject to rights of use, reproduction or representation that have been granted. They may not be used without the prior written authorisation of the Company.</span></p>
            <p className = 'western'><span lang = 'en-US'>9.5 Any databases provided to Clients are the property of the Company, which acts as the database controller. You may not extract or re-use a significant qualitative or quantitative part of the databases, including for your own personal use.</span></p>
            <p
                className = 'western'
                lang = 'en-US'>&nbsp;
            </p>
            <p className = 'western'><strong>1</strong><span lang = 'en-US'><strong>0</strong></span><strong>. Final provisions</strong></p>
            <p className = 'western'><span lang = 'en-US'>10.1 The age of the user of the personal account cannot be less than 18 years or the age of majority established by the legislation of your country of registration. </span></p>
            <p className = 'western'><span lang = 'en-US'>10.2 If you are under the age of majority or if you do not agree with the Terms of Use of the Personal Area, we recommend you to leave this portal.</span></p>
            <p className = 'western'><span lang = 'en-US'>10.3 All appendices, amendments and additions to the Terms and Conditions are an integral part thereof.</span></p>
            <p className = 'western'>&nbsp;</p>
        </div>

    );
}

export default AccountTerms;
