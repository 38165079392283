// eslint-disable-line
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiActions } from '../../../bus/actions';
import { Dialog } from '@mui/material';
import { saveSession } from '../../../instruments';
import { api } from '../../../Api';
import StylesForm from '../../../theme/styles/form.module.scss';
import StylesFilter from '../../../theme/styles/filter.module.scss';
import StepDoc from '../Invite/StepDoc';
import Styles from '../Invite/styles.module.scss';

const selectState = (state) => ({
    isFetching: state.swapi.isFetching,
    isLogged:   state.swapi.isLogged,
    userData:   state.swapi.userData,
    lang:       state.swapi.lang,

});

export const Agreement = () => {
    const state = useSelector(selectState);
    const dispatch = useDispatch();
    const isLogged = state.isLogged;
    const userData = state.userData;
    const [ open, setOpen ] = useState(false);
    const [ isDoc, setIsDoc ] = useState(false);
    const lang = state.lang;

    useEffect(() => {
        if (isLogged && userData && userData.role === 'client' && !userData.agreement && !window.location.pathname.includes('terms')) {
            setOpen(true);
        }
    }, [ isLogged, userData ]);

    const handleOk =  async () => {
        const props = {
            token: userData.token,
        };
        const data = await api.postData(props, '/agreement');
        if (data && data.status) {
            const user = {
                ...userData,
                agreement: true,
            };
            dispatch(apiActions.setUserData(user));
            saveSession(user);
            setIsDoc(true);
        }
    };
    const sendData = async (data) => {
        const formData = new FormData();
        formData.append('lang', lang);
        formData.append('uid', userData.token);
        console.log(userData.token);
        for (const key of Object.keys(data)
        ) {
            if (key !== 'files') {
                formData.append(key, data[ key ]);
            } else if (data.files && data.files.length > 0) {
                data.files.forEach((file) => {
                    formData.append("files", file); // "files" - ключ для файлів
                });
            }
        }
        const response = await api.postFile(formData, '/saveDocx');
        console.log(response);
        if (response.success) {
            setOpen(false);
        }
    };
    // const handleClose = () => {
    //     setOpen(false);
    //     removeSession();
    //     dispatch(apiActions.setIsLogged(false));
    //     dispatch(apiActions.setUserData(null));
    //     dispatch(apiActions.setCompany({}));
    //     const url = '/login';
    //     navigate(url);
    // };

    return (
        <>
            <Dialog
                PaperProps = {{
                    sx: {
                        width:   '900px',
                        padding: '32px',
                        gap:     '32px',
                    },
                }}
                open = { open }>
                {isDoc ? (
                    <div className = { `${Styles.wrap} ` } >

                        <div className = { `${Styles.content} ` } >
                            <StepDoc
                                callback = { sendData }
                                role = { 1 }
                            />
                        </div>
                    </div>

                ) : (
                    <>
                        <div className = { `${StylesForm.title}` }>{`Terms of Use`}</div>
                        <div>
                            <p>
                                {`By using this website, you agree to our `}
                                <a
                                    href = '/terms'
                                    target = '_blank'>
                                    {`Terms of Use`}
                                </a>,
                                <a
                                    href = '#'
                                    target = '_blank'>{` Privacy Policy`}
                                </a>,
                                {` and `}
                                <a
                                    href = '#'
                                    target = '_blank' >{`Cookie Policy`}
                                </a>
                                {`. Please read these documents carefully.`}
                            </p>
                            <p>
                                {`If you do not agree with these terms, you must not use our website or services.`}
                            </p>
                        </div>
                        <div className = { StylesFilter.dialogButtons }>
                            <div
                                className = { StylesFilter.btn }
                                onClick = { () => handleOk() }>
                                {`I Agree`}
                            </div>
                        </div>
                    </>
                )}

            </Dialog>

        </>
    );
};
