import React, { useEffect, useState } from 'react';
import ListConstructor from '../ListConstructor';
import { listing } from './listing';
import { api } from '../../../Api';

import Styles from '../../../theme/styles/pageHeader.module.scss';
import { Status } from './status';
import { useSelector } from 'react-redux';
import { setTraslate } from '../../../instruments/setTraslate';

const selectState = (state) => ({
    translate: state.swapi.translate,
});

function AccountserviceCompany(props) {
    const state = useSelector(selectState);
    const translate = state.translate;
    const [ list, setList ] = useState({ ...listing.list });
    const [ listAdd, setListAdd ] = useState({ ...listing.listing_additional });
    const [ tab, setTab ] = useState(0);
    const [ filter, setFilter ] = useState({});

    useEffect(()=>{
        const _list = setTraslate({ ...listing.list }, translate);
        setList(_list);
        const _listAdd = setTraslate({ ...listing.listing_additional }, translate);
        setListAdd(_listAdd);
    }, [ translate, listing ]);

    const CustomComponents = {
        status: Status,
    };
    const setCallBack = (value) => {
        setFilter(value);
    };
    const exportFile = async() => {
        await api.postDataDownload({ ...filter }, tab === 0 ? '/exportServiceCompany' : '/exportServiceCompanyAdditional');
    };

    return (
        <>
            <div className = { Styles.header }>
                <div className = { Styles.title }>
                    { translate.mn5 }
                </div>
                <div className = { Styles.filter }>
                    <div
                        className = { Styles.download }
                        onClick = { ()=>exportFile() }>
                        <img
                            alt = ''
                            src = { '/icons/download.svg' }
                        />
                        {translate.bt10}
                    </div>
                </div>

            </div>
            <div className = { Styles.tabs }>
                <div
                    className = { `${Styles.tab} ${tab === 0 ? Styles.active : ''}` }
                    onClick = { ()=>setTab(0) }>
                    { translate.t3 }
                </div>
                <div
                    className = { `${Styles.tab} ${tab === 1 ? Styles.active : ''}` }
                    onClick = { ()=>setTab(1) }>
                    { translate.t4 }
                </div>
            </div>

            {tab === 0 && (
                <ListConstructor
                    { ...props }
                    isShowAddBtn
                    CustomComponents = { CustomComponents }
                    fields = { list }
                    limit = { 30 }
                    setCallBack = { setCallBack }
                    sortDefault = 'next_pay'
                    sortOrderDefault = { false }
                    type = { 'serviceCompany' }
                    urlApi = { '/serviceCompany' }

                />
            ) }
            {tab === 1 && (
                <ListConstructor
                    { ...props }
                    isShowAddBtn
                    CustomComponents = { CustomComponents }
                    fields = { listAdd }
                    limit = { 30 }
                    setCallBack = { setCallBack }
                    sortDefault = 'next_pay'
                    sortOrderDefault = { false }
                    type = { 'serviceCompanyAdditional' }
                    urlApi = { '/serviceCompanyAdditional' }

                />

            ) }
        </>
    );
}

export default AccountserviceCompany;
