export const en = {
    code:  'en',
    title: 'English',
    l1:    'Welcome back!',
    l2:    'Enter your email and password to log in to your account.',
    l3:    'Email',
    l4:    'Password',
    l5:    'User not found',
    l6:    'Wrong password',
    l7:    'Forgot password?',
    l8:    'Login',
    l9:    'Wrong invite link',
    l10:   'Welcome! Start registration!',
    l11:   'Enter your email and create a password to register',
    l12:   'Next',
    cl1:   'Your time',
    cl2:   'Work time',
    hb1:   'Additional balance',
    hb2:   'My balance',
    so:    'Sign out',
    mn1:   'Clients',
    mn2:   'Companies',
    mn3:   'Numbers',
    mn4:   'Call Statistics',
    mn5:   'Services',
    mn6:   'Providers',
    mn7:   'Analytics',
    mn8:   'Service Balance',
    mn9:   'Settings',
    mn10:  'Profit',
    mn11:  'Tech',
    sb1:   'Hide panel',
    sb2:   'Open panel',
    ad1:   'Add User',
    ad2:   'Add Company',
    ad3:   'Add Number',
    ad4:   'Add Provider',
    ad5:   'Add',
    ed1:   'Edit User',
    ed2:   'Edit Company',
    ed3:   'Edit Number',
    ed4:   'Edit Provider',
    bt1:   'Go back',
    bt2:   'Save',
    bt3:   'Search',
    bt4:   'Filters',
    bt5:   'Clear filters',
    bt6:   'Merge',
    bt7:   'Connect company numbers',
    bt8:   'Load supplier numbers',
    bt9:   'Disconnect numbers',
    bt10:  'Upload',
    bt11:  'Invite user',
    bt12:  'Additional services',
    pg1:   'Go to',
    tx1:   'There`s nothing here yet, but that won`t last long! 😉',
    tx2:   'All',
    tx3:   'last month',
    tx4:   'Total',
    tx5:   'Country',
    tx6:   'countries',
    tx7:   'Active',
    tx8:   'Inactive',
    tx9:   'Server response error',
    t1:    'Call statistics',
    t2:    'Region statistics',
    t3:    'My numbers',
    t4:    'Additional services',
    t5:    'List',
    t6:    'Analytics',
    st1:   '% of dials',
    st2:   'Average Call Duration',
    st3:   'Calls',
    st4:   'Costs',
    st5:   'Session information.',
    st6:   'Time on call',
    st7:   'Costs by geolocation',
    st8:   'Overall Analytics',
    st9:   'Diagram',
    st10:  'Dynamics',
    st11:  'Supplier Replenishment',
    st12:  'Replenishment',
    st13:  'Replenishment Amount',
    st14:  'Replenish',
    st15:  'Enter Amount',
    st16:  'Balance Replenishment',
    f1:    'Username',
    f2:    'Telegram ID',
    f3:    'Role',
    f4:    'Companies',
    f5:    'Representative Percentage',
    f6:    'Partner Percentage',
    f6_:   'Partner Percentage trafic',
    f7:    'Password',
    f8:    'Email',
    f9:    'Action',
    f10:   'Company',
    f11:   'MOR',
    f12:   'ATS',
    f13:   'Manager',
    f14:   'Credit limit',
    f15:   'MOR List',
    f16:   'Payment date',
    f17:   'Service balance/Phone balance',
    f18:   'Partner',
    f19:   'Country',
    f20:   'Phone',
    f21:   'Type',
    f22:   'Supplier',
    f23:   'Price Supplier',
    f24:   'Subscription Supplier',
    f25:   'Price for company',
    f26:   'Subscription for company',
    f27:   'Deletion from supplier',
    f28:   'Reserve',
    f29:   'Date created',
    f30:   'Date',
    f31:   'Number',
    f32:   'Call to',
    f33:   'Duration',
    f34:   'Status',
    f35:   'Reason for hanging',
    f36:   'Server',
    f37:   'Profit',
    f38:   'Date of connection',
    f39:   'Date of writing off',
    f40:   'Cost of connection',
    f41:   'Subscription fee for the number',
    f42:   'Name',
    f43:   'Subscription fee',
    f44:   'Balance',
    f45:   'Subscription charge when connecting',
    f46:   'Representative',
    f47:   'Representative percentage',
    f49:   'Comment',
    f50:   'Amount',
    f51:   'Service',
    f52:   'Transaction amount',
    f53:   'Purchase amount',
    f54:   'Manager bonus',
    f55:   'Sur bonus',
    f56:   'Partner bonus',
    f57:   'Written off / topped up',
    f58:   'Leftover',
    f59:   'Description',
    f60:   'Is deleded',
    f61:   'Spam Checker',
    f62:   'SMS',
    f63:   'Total Profit',
    f65:   'Profit by Calls',
    f66:   'Profit by Numbers',
    f67:   'Profit by PBX',
    f68:   'Profit by SMS',
    f69:   'Profit by Spam Checker',
    f70:   'Information',
    f71:   'Number of lines',
    f72:   'Price per min supplier',
    f73:   'Price per min company',
    f74:   'Date of connection partner',
    f75:   'Atc price purchase',
    f76:   'Atc price',
    in1:   'Select type',
    in2:   'This will help us determine which documents need to be prepared.',
    in3:   'Individual',
    in4:   'Legal entity',
    in5:   'Continue',
    in6:   'Enter personal details',
    in7:   'Fill in additional information so we can verify your identity',
    in8:   'I have read and accept',
    in9:   'Terms and Conditions',
    in10:  'platforms.',
    in11:  'Upload documents',
    in12:  'To complete the verification process, upload a photo of your passport.',
    in13:  'Make sure that:',
    in14:  'The photo is clear and without blur.',
    in15:  'All corners of the document are fully visible.',
    in16:  'Text and images are easy to read.',
    in17:  'JPEG, PNG, PDF (up to 5 MB)',
    in18:  'We are working on your verification!',
    in19:  'As soon as we complete your verification, you will receive an email notification:',
    in20:  'Usually it takes up to 2 hours.',
    in21:  'If you have any questions, please contact our manager for help.',
    in22:  'Enter the company name',
    in23:  'Download the contract',
    in24:  'Read the contract by downloading it from the link.',
    in25:  'Download the contract',
    in26:  'To complete the verification process, upload a photo of your contract.',
    in27:  'First name',
    in28:  'Last name',
    in29:  'Company name',
};
