const listing = {};

listing.list = {
    calldate: {
        type:   'date',
        title:  'f30',
        width:  '5',
        sort:   true,
        filter: 'DateRangeField',

    },
    country: {
        type:   'text',
        title:  'f19',
        width:  '5',
        filter: 'MultiSearch',
        // sort_filter: 2,
    },
    src: {
        type:        'text',
        title:       'f31',
        filter:      'Input',
        width:       '5',
        sort_filter: 1,
    },
    dst: {
        type:  'text',
        title: 'f32',
        width: '5',
    },
    duration: {
        type:  'text',
        title: 'f33',
        width: '5',
        sort:  true,

    },
    disposition: {
        type:        'text',
        title:       'f34',
        width:       '5',
        filter:      'Select',
        sort_filter: 100,

    },

    type_compamy: {
        type:  'text',
        title: 'f36',
        width: '3',
        roles: [ 'admin', 'manager' ],

    },
    provider_name: {
        type:   'text',
        title:  'f22',
        width:  '5',
        filter: 'Input',
        roles:  [ 'admin', 'manager' ],


    },
    provider_rate: {
        type:  'text',
        title: 'f72',
        width: '2',
        roles: [ 'admin', 'manager' ],
        sort:  true,


    },
    provider_price: {
        type:  'text',
        title: 'f23',
        width: '2',
        roles: [ 'admin', 'manager' ],
        sort:  true,


    },
    companyId: {
        type:        'text',
        title:       'f10',
        width:       '5',
        typeSearch:  'companyId',
        roles:       [ 'admin', 'manager' ],
        filter:      'SearchV2',
        sort_filter: 2,
    },
    user_rate: {
        type:  'text',
        title: 'f73',
        width: '2',
        roles: [ 'admin', 'manager' ],
        sort:  true,


    },
    user_price: {
        type:  'text',
        title: 'f25',
        width: '2',
        sort:  true,

    },
    partnerCompanyPercent: {
        type:  'text',
        title: 'f56',
        width: '2',
        roles: [ 'admin', 'manager' ],
        sort:  true,

    },
    profit: {
        type:  'text',
        title: 'f37',
        width: '2',
        roles: [ 'admin', 'manager' ],
        sort:  true,

    },

};

export { listing };
